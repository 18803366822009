import { Dispatch, SetStateAction, useState, useMemo } from 'react';
import { toast } from 'sonner';
import { IAPIFormFields, ParsedData } from '../types/task-apis.type';
import {
	ApiCredentialTypes,
	dataFieldsMap,
	DataPassingMethod,
} from '../types/task-apis.enum';
import { useGraphQlCurlParser } from './useGraphQlCurlParser';

export const useCurlParser = (
	formFields: IAPIFormFields,
	setFormFields: (fields: IAPIFormFields) => void,
	parsedData: ParsedData,
	setParsedData: Dispatch<SetStateAction<ParsedData>>,
) => {
	const [error, setError] = useState<string>('');

	const graphQlParser = useGraphQlCurlParser(
		formFields,
		setFormFields,
		parsedData,
		setParsedData,
	);

	const parseCurl = (curl: string): ParsedData | null => {
		setError('');
		const parsed: ParsedData = {
			method: 'GET',
			url: '',
			headers: {},
			data: null,
			params: {},
		};

		// Normalize the curl command
		curl = curl.replace(/\\\n/g, ' ').replace(/\s+/g, ' ').trim();

		// Normalize flags (case-insensitive)
		curl = curl.replace(/-(h|header|headers|H)\s+/gi, '-H ');
		curl = curl.replace(
			/-(d|data|data-raw|data-urlencode|--data-binary)\s+/gi,
			'-d ',
		);
		curl = curl.replace(/-(X|request)\s+/gi, '-X ');
		curl = curl.replace(/--location\s+/gi, '-L ');

		// Extract method
		const methodMatch = curl.match(/(?:^|\s)(?:-X\s+)(\w+)/i);
		if (methodMatch) {
			parsed.method = methodMatch[1].toUpperCase();
		} else if (curl.includes('-d ') || curl.includes('--data')) {
			parsed.method = 'POST';
		}

		// Extract URL
		const urlMatch = curl.match(
			/(?:curl\s+|(?:-[LX]\s+)+)?['"]?(https?:\/\/[^\s'"]+)/i,
		);
		if (urlMatch) {
			parsed.url = urlMatch[1];
			// Extract query parameters
			const [baseUrl, queryString] = parsed.url.split('?');
			parsed.url = baseUrl;
			if (queryString) {
				parsed.params = Object.fromEntries(new URLSearchParams(queryString));
			}
		} else {
			setError('Invalid cURL: URL not found');
			return null;
		}

		// Extract headers
		const headerRegex = /-H\s+['"]?([^:]+):\s*([^'"]+)['"]?/gi;
		let headerMatch;
		const commonHeaders = ['accept', 'content-type'];
		while ((headerMatch = headerRegex.exec(curl)) !== null) {
			const [, key, value] = headerMatch;
			// if (!commonHeaders.includes(key.trim().toLowerCase())) {
			// 	parsed.headers[key.trim()] = value.trim();
			// }
			parsed.headers[key.trim()] = value.trim();
		}

		// Remove the auth header check and authHeader field
		// The Authorization header will now be included in the general headers

		// Extract data
		const dataRegex =
			/-d\s+(['"])([\s\S]+?)\1|-d\s+(['"]?)([\s\S]+?)\3(?:\s|$)/gi;
		const dataMatches = [...curl.matchAll(dataRegex)];
		if (dataMatches.length > 0) {
			const dataContent = dataMatches
				.map((match) => match[2] || match[4])
				.join('');
			try {
				parsed.data = JSON.parse(dataContent);
			} catch (e) {
				// If it's not JSON, check if it's form data
				if (dataContent.includes('&') && dataContent.includes('=')) {
					parsed.data = Object.fromEntries(
						new URLSearchParams(dataContent),
					);
				} else {
					// If it's not form data, treat it as a string
					parsed.data = dataContent.trim();
				}
			}
		} else {
			// If no data is found, set it to an empty object instead of null
			parsed.data = {};
		}

		// Handle form data (-F or --form)
		const formDataRegex = /(?:-F|--form)\s+(['"]?)([^=]+)=([^'"]+)\1/g;
		let formDataMatch;
		const formData = {};
		while ((formDataMatch = formDataRegex.exec(curl)) !== null) {
			const [, , key, value] = formDataMatch;
			formData[key.trim()] = value.trim();
		}
		if (Object.keys(formData).length > 0) {
			parsed.data = formData;
		}

		// Handle basic auth
		const basicAuthMatch = curl.match(/-u\s+(['"]?)([^:]+):([^'"]+)\1/);
		if (basicAuthMatch) {
			const [, , username, password] = basicAuthMatch;
			parsed.authHeader = {
				key: 'Authorization',
				value: `Basic ${btoa(`${username}:${password}`)}`,
			};
		}

		// Final validation
		if (!parsed.url) {
			setError('Invalid cURL: Unable to parse URL');
			return null;
		}

		// Ensure data is always an object
		if (typeof parsed.data !== 'object' || parsed.data === null) {
			parsed.data = { rawData: parsed.data };
		}

		// console.log('Parsed cURL data:', parsed);

		return parsed;
	};

	const handleParse = (errorCallback?: (error: string) => void) => {
		if (formFields.apiCredentialsType === ApiCredentialTypes.GraphQl) {
			return graphQlParser.handleParse(errorCallback);
		}

		const result = parseCurl(formFields?.curlCommand);

		if (result) {
			setParsedData(result);

			// Combine data and params for apiPayload
			const combinedPayload = {
				...(result.data || {}),
				...(result.params || {}),
			};

			// Get keys from apiConstantDataFields
			const constantKeys =
				formFields.apiConstantDataFields?.map((item) => item.key) || [];

			// Filter out keys that are in apiConstantDataFields
			const filteredPayload = Object.entries(combinedPayload)
				.filter(([key]) => !constantKeys.includes(key))
				.map(([key, value]) => ({
					key,
					value: dataFieldsMap[key] || '',
					testValue: String(value),
				}));

			// Update formFields with parsed data
			setFormFields({
				...formFields,
				apiMethod: result.method,
				apiEndpoint: result.url,
				apiPayloadMethod:
					Object.keys(result.params).length > 0
						? DataPassingMethod.QueryParams
						: DataPassingMethod.Body,
				apiHeaders: Object.entries(result.headers).map(([key, value]) => ({
					key,
					value,
				})),
				apiPayload: filteredPayload,
				isHeaderRequired: Object.keys(result.headers).length > 0,
			});

			return true; // Parsing successful
		} else {
			setParsedData(null);
			const errorMessage =
				error ||
				'Failed to parse cURL command. Please check the syntax and try again.';
			toast.error(errorMessage);
			if (errorCallback) {
				errorCallback(errorMessage);
			}
			return false; // Parsing failed
		}
	};

	const getPlaceholder = () => {
		if (formFields?.apiCredentialsType === ApiCredentialTypes.GraphQl) {
			return graphQlParser.getPlaceholder();
		}

		return `curl --location 'https://api.rango.exchange/intract/campaign/intract-roll-ups-challenge-core-zksync-1?apiKey=SOME_API_KEY' \\
--header 'Content-Type: application/json' \\
--data '{
    "address": "SOME_WALLET_ADDRESS"
}'`;
	};

	return {
		parsedData,
		error,
		handleParse,
		getPlaceholder,
	};
};
