import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { Badge } from '@/components/ui/badge';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { KOLCard } from '../components/KOLCard';
import KOLFilters from '../components/KOLFilters';
import { IKols } from '../hooks/useKOLMarketplace';
import useKolFilters from '../hooks/useKolFilters';
import { getKols } from '../services/kol-marketplace.service';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { useAuth } from '@/hooks/useAuth';
import { useInView } from 'react-intersection-observer';
import Spinner from '@/components/element/loading/Spinner';
import { useDebounce } from '@/hooks/useDebounce';

const LIMIT = 20;

const KOLMarketplaceRoutes = () => {
	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState({
		language: [],
		region: [],
		channels: [],
		price: [],
	});

	const debouncedSearch = useDebounce(search, 500);
	const { user } = useAuth();
	const { ref, inView } = useInView();

	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		status,
		refetch,
		isFetching,
	} = useInfiniteQuery<IKols[], Error>({
		queryKey: ['kols-marketplace', debouncedSearch, filters],
		queryFn: ({ pageParam = 1 }) =>
			getKols({
				limit: LIMIT,
				page: pageParam as number,
				search: debouncedSearch,
				channels: filters.channels,
			}),
		getNextPageParam: (lastPage, pages) =>
			lastPage.length < LIMIT ? undefined : pages.length + 1,
		initialPageParam: 1,
	});

	const kolsData = data
		? data?.pages?.flat()?.filter((kol) => kol && typeof kol === 'object')
		: [];

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

	useEffect(() => {
		analytics.track(TrackingEvents.KOLMarketplaceViewed, {
			userId: user?._id,
			enterpriseId: user?.enterpriseId,
		});
	}, [user]);

	if (status === 'pending' && !isFetching) return <FullPageLoading />;

	return (
		<div className="relative">
			<Badge className="rounded-full px-3" variant="outline">
				<i className="bi-stars me-1 text-yellow-600"></i>
				Build your Hype Tribe
			</Badge>
			<div className="text-2xl font-medium tracking-tight mt-4">
				KOLs Marketplace
			</div>
			<div className="text-md mt-2 mb-4">
				You need a
				<span className="px-1 text-violet-600 font-medium">
					right partner
				</span>{' '}
				to reach the world
			</div>
			<div className="sticky top-[64.5px] py-4 z-[41] bg-white">
				<KOLFilters
					search={search}
					filters={filters}
					setSearch={setSearch}
					setFilters={setFilters}
				/>
			</div>
			<div className="grid lg:grid-cols-3 2xl:grid-cols-4 gap-5 mt-8">
				{kolsData?.map((kol: IKols) => <KOLCard key={kol._id} kol={kol} />)}
			</div>
			<div
				ref={ref}
				className="mt-4 text-center text-sm font-medium text-slate-700"
			>
				{isFetchingNextPage || isFetching ? (
					<Spinner />
				) : hasNextPage ? (
					<Spinner />
				) : kolsData?.length > 0 ? (
					'No more KOLs to load'
				) : (
					!isFetching && 'No KOLs found'
				)}
			</div>
		</div>
	);
};

export default KOLMarketplaceRoutes;
