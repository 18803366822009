import { Media } from '@/components/element/media/Media';
import { CampaignRewardCategory } from '@/features/campaigns/types';
import { formatCompactNumber } from '@/utils/parsers';

export const RewardContent = ({
	reward,
	rewardValue,
	rewardPerUser = null,
	rewardType,
	noSeparator = false,
}) => {
	if (rewardValue === null || rewardValue === undefined) return null;

	// Use totalReward only when rewardPerUser is provided, otherwise just use rewardValue
	const totalReward = rewardPerUser
		? Number(rewardValue) * Number(rewardPerUser)
		: rewardValue;

	switch (rewardType) {
		case CampaignRewardCategory.Token:
			return (
				<>
					<DotSeparator noSeparator={noSeparator} />
					<span className="flex items-center">
						<Media
							src={reward?.rewardDetails?.logo}
							altText="token-logo"
							className="size-4 me-1"
						/>
						{`${formatCompactNumber(totalReward)} ${reward.rewardDetails?.symbol}`}
					</span>
				</>
			);
		case CampaignRewardCategory.Nft:
			return (
				<>
					<DotSeparator noSeparator={noSeparator} />
					<span className="flex items-center">
						<Media
							src="https://static.highongrowth.xyz/enterprise/66e816db019c8468efc9c6ee/7a59196cf53d4c60bb119f4e88308873.svg"
							altText="nft-icon"
							className="h-[16px]"
						/>
						{formatCompactNumber(totalReward)}
					</span>
				</>
			);
		case CampaignRewardCategory.DiscordRole:
			return (
				<>
					<DotSeparator noSeparator={noSeparator} />
					<span className="flex items-center">
						<i className="bi bi-discord me-1 text-slate-600" />
						{formatCompactNumber(totalReward)}
					</span>
				</>
			);
		case CampaignRewardCategory.OfficialPoints:
			return (
				<>
					<DotSeparator noSeparator={noSeparator} />
					<span className="flex items-center">
						<i className="bi bi-stars me-1 text-slate-600" />
						{formatCompactNumber(totalReward)}
					</span>
				</>
			);
		case CampaignRewardCategory.Whitelist:
			return (
				<>
					<DotSeparator noSeparator={noSeparator} />
					<span className="flex items-center">
						<i className="bi bi-gift-fill me-1 text-slate-600" />
						{formatCompactNumber(totalReward)}
					</span>
				</>
			);
		default:
			return (
				<>
					<DotSeparator noSeparator={noSeparator} />
					{formatCompactNumber(totalReward)}
				</>
			);
	}
};

const DotSeparator = ({ noSeparator }: { noSeparator: boolean }) =>
	!noSeparator ? (
		<span className="size-1 bg-slate-300 mx-1.5 rounded-full"></span>
	) : null;
