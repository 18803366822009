import RewardMethodSelect from '../../input/RewardMethodSelect';
import { CampaignRewardType, ICampaignAddReward } from '@/features/campaigns/types';
import { useMemo } from 'react';
import InputText from '@/components/element/inputs/InputText';

export const TokenRewardMethod = ({
	tokenReward,
	setTokenReward,
	errors,
	noEndTime,
}: {
	tokenReward: ICampaignAddReward;
	setTokenReward: (reward: ICampaignAddReward) => void;
	errors: {
		method: boolean | string;
		chain: boolean;
		token: boolean;
		winners: boolean;
		reward: boolean;
		expected: boolean;
	};
	noEndTime: boolean;
}) => {
	const allowedMethods = useMemo(() => {
		const methods = [CampaignRewardType.Fcfs];
		if (!noEndTime) {
			methods.push(CampaignRewardType.LuckyDraw);
			methods.push(CampaignRewardType.Leaderboard);
			methods.push(CampaignRewardType.SmartRaffle);
		}
		return methods;
	}, [noEndTime]);

	return (
		<div className="mb-8 px-6">
			<div className="grid grid-cols-2 gap-2 mb-1">
				<div
					className={
						tokenReward.method === CampaignRewardType.SmartRaffle
							? `col-span-1`
							: 'col-span-2'
					}
				>
					<div className="text-sm font-medium mb-1">Reward Method</div>
					<RewardMethodSelect
						allowedMethods={allowedMethods}
						value={tokenReward?.method}
						setValue={(value) =>
							setTokenReward({
								...tokenReward,
								method: value as CampaignRewardType,
								autoRewardDistribution:
									value === CampaignRewardType.Leaderboard
										? true
										: false,
							})
						}
						error={!!errors?.method}
						errorText={errors?.method || 'Please select a reward method'}
					/>
				</div>
				{tokenReward.method === CampaignRewardType.SmartRaffle && (
					<InputText
						tooltip="To calculate the probability of winning, we need to know the expected number of participants."
						label="Expected Participants"
						placeholder="1000"
						value={tokenReward.countExpected}
						setValue={(e) =>
							setTokenReward({ ...tokenReward, countExpected: e })
						}
						error={errors?.winners}
						errorText="Please enter the expected participants"
						type="number"
					/>
				)}
			</div>
		</div>
	);
};
