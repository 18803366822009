export const getScanLink = (
	address: string,
	chainId: number,
	type: 'address' | 'tx' = 'address',
) => {
	switch (chainId) {
		case 1:
			return `https://etherscan.io/${type}/${address}`;
		case 56:
			return `https://bscscan.com/${type}/${address}`;
		case 137:
			return `https://polygonscan.com/${type}/${address}`;
		case 250:
			return `https://ftmscan.com/${type}/${address}`;
		case 43114:
			return `https://cchain.explorer.avax.network/${type}/${address}`;
		case 42161:
			return `https://arbiscan.io/${type}/${address}`;
		case 80001:
			return `https://explorer-mumbai.maticvigil.com/${type}/${address}`;
		case 122:
			return `https://explorer.fuse.io/${type}/${address}`;
		case 123:
			return `https://explorer.fusespark.io/${type}/${address}`;
		case 324:
			return `https://explorer.zksync.io/${type}/${address}`;
		case 280:
			return `https://zksync2-testnet.zkscan.io/${type}/${address}`;
		case 8453:
			return `https://basescan.org/${type}/${address}`;
		case 84531:
			return `https://goerli.basescan.org/${type}/${address}`;
		case 59144:
			return `https://lineascan.build/${type}/${address}`;
		case 534351:
			return `https://sepolia-blockscout.scroll.io/${type}/${address}`;
		case 169:
			return `https://pacific-explorer.manta.network/${type}/${address}`;
		case 3441005:
			return `https://pacific-explorer.testnet.manta.network/${type}/${address}`;
		case 204:
			return `https://opbnbscan.com/${type}/${address}`;
		case 34443:
			return `https://explorer.mode.network/${type}/${address}`;
		case 81457:
			return `https://blastscan.io/${type}/${address}`;
		case 1442:
			return `https://testnet-zkevm.polygonscan.com/${type}/${address}`;
		case 196:
			return `https://www.oklink.com/xlayer/${type}/${address}`;
		case 5000:
			return `https://explorer.mantle.xyz/${type}/${address}`;
		case 223:
			return `https://explorer.bsquared.network/${type}/${address}`;
		case 60808:
			return `https://explorer.gobob.xyz/${type}/${address}`;
		case 1030:
			return `https://evm.confluxscan.io/${type}/${address}`;
		case 9789:
			return `https://testnet.tabiscan.com/${type}/${address}`;
		case 255:
			return `https://kromascan.com/${type}/${address}`;
		case 30:
			return `https://explorer.rootstock.io/${type}/${address}`;
		case 5234:
			return `https://humanode.subscan.io/${type}/${address}`;
		case 185:
			return `https://explorer.mintchain.io/${type}/${address}`;
		case 88:
			return `https://vicscan.xyz/${type}/${address}`;
		case 80084:
			return `https://bartio.beratrail.io/${type}/${address}`;
		case 245022934:
			return `https://neonscan.org/${type}/${address}`;
		case 1329:
			return `https://seitrace.com/${type}/${address}?chain=pacific-1`;
		case 83144:
			return `https://explorer.testnet.xprotocol.org/${type}/${address}`;
		case 7000:
			return `https://explorer.zetachain.io/${type}/${address}`;
		case 40:
			return `https://teloscan.io/${type}/${address}`;
		case 1946:
			return `https://explorer-testnet.soneium.org/${type}/${address}`;
		case 388:
			return `https://explorer.zkevm.cronos.org/${type}/${address}`;
		case 282:
			return `https://explorer.zkevm.cronos.org/testnet/${type}/${address}`;
		default:
			return '';
	}
};

// export const NETWORK_MAP = {
// 	137: {
// 		chainId: '0x89',
// 		chainName: 'Polygon Mainnet',
// 		nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
// 		rpcUrls: ['https://polygon-rpc.com'],
// 		blockExplorerUrls: ['https://www.polygonscan.com/'],
// 		logoUrl: 'https://cryptologos.cc/logos/thumbs/polygon.png?v=30',
// 	},
// 	80001: {
// 		chainId: '0x13881',
// 		chainName: 'Mumbai Testnet',
// 		nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
// 		rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
// 		blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
// 		logoUrl: 'https://cryptologos.cc/logos/thumbs/polygon.png?v=30',
// 	},
// 	56: {
// 		chainId: '0x38',
// 		chainName: 'BNB',
// 		nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
// 		rpcUrls: ['https://bsc-dataseed.binance.org/'],
// 		blockExplorerUrls: ['https://bscscan.com/'],
// 		logoUrl: 'https://cryptologos.cc/logos/thumbs/bnb.png?v=030',
// 	},
// 	43114: {
// 		chainId: '0xa86a',
// 		chainName: 'Avalanche Mainnet',
// 		nativeCurrency: { name: 'AVAX', symbol: 'AVAX', decimals: 18 },
// 		rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
// 		blockExplorerUrls: ['https://cchain.explorer.avax.network/'],
// 		logoUrl: 'https://cryptologos.cc/logos/thumbs/avalanche.png?v=030',
// 	},
// 	42161: {
// 		chainId: '0xA4B1',
// 		chainName: 'Arbitrum Mainnet',
// 		nativeCurrency: { name: 'ARBETH', symbol: 'ETH', decimals: 18 },
// 		rpcUrls: ['https://arb1.arbitrum.io/rpc'],
// 		blockExplorerUrls: ['https://arbiscan.io/'],
// 		logoUrl: 'https://cryptologos.cc/logos/thumbs/arbitrum.png?v=030',
// 	},
// 	250: {
// 		chainId: '0xfa',
// 		chainName: 'Fantom',
// 		nativeCurrency: { name: 'FTM', symbol: 'FTM', decimals: 18 },
// 		rpcUrls: ['https://rpc.ankr.com/fantom/'],
// 		blockExplorerUrls: ['https://ftmscan.com/'],
// 		logoUrl: 'https://cryptologos.cc/logos/thumbs/fantom.png?v=030',
// 	},
// 	122: {
// 		chainId: '0x7a',
// 		chainName: 'Fuse Mainnet',

// 		nativeCurrency: {
// 			decimals: 18,
// 			name: 'Ether',
// 			symbol: 'FUSE',
// 		},
// 		blockExplorerUrls: ['https://explorer.fuse.io'],
// 		rpcUrls: ['https://rpc.fuse.io'],
// 	},
// 	123: {
// 		id: '0x7b',
// 		chainName: 'Fuse Sparknet',
// 		rpcUrls: ['https://rpc.fusespark.io'],
// 		nativeCurrency: {
// 			decimals: 18,
// 			name: 'Ether',
// 			symbol: 'SPARK',
// 		},
// 		blockExplorerUrls: ['https://explorer.fusespark.io'],
// 	},
// 	324: {
// 		chainId: '0x144',
// 		chainName: 'zkSync Era Mainnet',
// 		nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
// 		rpcUrls: ['https://mainnet.era.zksync.io'],
// 		blockExplorerUrls: ['https://explorer.zksync.io'],
// 	},
// 	8453: {
// 		chainId: '0x2105',
// 		chainName: 'Base',
// 		nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
// 		rpcUrls: ['https://mainnet.base.org'],
// 		blockExplorerUrls: ['https://basescan.org/'],
// 		logoUrl: 'https://avatars.githubusercontent.com/u/108554348?s=280&v=4',
// 	},
// 	59144: {
// 		chainId: '0xe708',
// 		chainName: 'Linea',
// 		nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
// 		rpcUrls: ['https://linea.drpc.org'],
// 		blockExplorerUrls: ['https://lineascan.build/'],
// 		logoUrl:
// 			'https://pbs.twimg.com/profile_images/1639402103486521344/erDLnbwE_400x400.jpg',
// 	},
// 	280: {
// 		chainId: '0x118',
// 		chainName: 'zkSync Era Testnet',
// 		nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
// 		rpcUrls: ['https://testnet.era.zksync.dev'],
// 		blockExplorerUrls: ['https://zksync2-testnet.zkscan.io'],
// 	},
// 	84531: {
// 		chainId: '0x14a33',
// 		chainName: 'Base Testnet',
// 		nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
// 		rpcUrls: ['https://base-goerli.public.blastapi.io'],
// 		blockExplorerUrls: ['https://goerli.basescan.org/'],
// 		logoUrl: 'https://avatars.githubusercontent.com/u/108554348?s=280&v=4',
// 	},
// 	534351: {
// 		chainId: '0x8274f',
// 		chainName: 'Scroll Sepolia Testnet',
// 		nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
// 		rpcUrls: ['https://rpc.ankr.com/scroll_sepolia_testnet'],
// 		blockExplorerUrls: ['https://sepolia-blockscout.scroll.io/'],
// 	},
// 	1442: {
// 		chainId: '0x5a2',
// 		chainName: 'ZkEVM Testnet',
// 		nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
// 		rpcUrls: ['https://testnet-zkevm.polygonscan.com'],
// 		blockExplorerUrls: ['https://testnet-zkevm.polygonscan.com'],
// 		logoUrl:
// 			'https://assets-global.website-files.com/6364e65656ab107e465325d2/642235057dbc06788f6c45c1_polygon-zkevm-logo.png',
// 	},
// 	34443: {
// 		chainId: '0x86a3',
// 		chainName: 'Mode Mainnet',
// 		nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
// 		rpcUrls: ['https://mainnet.mode.network'],
// 		blockExplorerUrls: ['https://explorer.mode.network/'],
// 		logoUrl:
// 			'https://pbs.twimg.com/profile_images/1688569679877390338/IYXD4bdy_400x400.jpg',
// 	},
// 	1101: {
// 		chainId: '0x44d',
// 		chainName: 'ZkEVM Mainnet',
// 		nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
// 		rpcUrls: ['https://zkevm-mainnet.zkscan.io'],
// 		blockExplorerUrls: ['https://zkevm-mainnet.zkscan.io'],
// 		logoUrl:
// 			'https://assets-global.website-files.com/6364e65656ab107e465325d2/642235057dbc06788f6c45c1_polygon-zkevm-logo.png',
// 	},
// 	10: {
// 		chainId: '0xa4b1',
// 		chainName: 'Optimism Mainnet',
// 		nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
// 		rpcUrls: ['https://mainnet.optimism.io'],
// 		blockExplorerUrls: ['https://optimism.io/'],
// 		logoUrl: 'https://cryptologos.cc/logos/thumbs/optimism-ethereum.png?v=030',
// 	},
// 	1: {
// 		chainId: '0x1',
// 		chainName: 'Ethereum Mainnet',
// 		nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
// 		rpcUrls: ['https://mainnet.infura.io/v3/'],
// 		blockExplorerUrls: ['https://etherscan.io/'],
// 		logoUrl: 'https://cryptologos.cc/logos/thumbs/ethereum.png?v=030',
// 	},
// };
