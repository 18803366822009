import { testAPI, testApiV2 } from '../services/task-apis.service';
import { queryClient } from '@/lib/react-query';
import { useMutation } from '@tanstack/react-query';
import { useConnectAPI } from './useConnectAPI';
import { ApiCredentialTypes, CreateTaskAPISteps } from '../types/task-apis.enum';
import { handleErrorMessage } from '@/utils/notifications';
import { IAPIFormFields } from '../types/task-apis.type';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { toast } from 'sonner';

const useTestAPI = () => {
	const {
		formFields,
		setFormFields,
		setFormErrors,
		setIsLoading,
		step,
		setStep,
		setEvaluationErrors,
		evaluationErrors,
	} = useConnectAPI();

	const handleTestRunSuccess = async (res) => {
		setFormFields((prev) => ({
			...prev,
			isApiTestDone: true,
			isApiSchemaValid: res?.isExpressionsValid?.isValid,
			responseSchema: res?.response?.data,
		}));
		analytics.track(TrackingEvents.TaskAPIsCreateTested, {
			isValid: res.isExpressionsValid?.isValid,
		});
		if (
			step === CreateTaskAPISteps.Testing &&
			res?.isExpressionsValid?.isValid
		) {
			setStep(CreateTaskAPISteps.ReadyToSave);
		}
		if (
			step === CreateTaskAPISteps.EvaluateResponse &&
			res?.isExpressionsValid?.isValid
		) {
			setStep(CreateTaskAPISteps.Upsert);
			toast.success('API is successfully tested and evaluated');
		} else if (
			step === CreateTaskAPISteps.EvaluateResponse &&
			!res?.isExpressionsValid?.isValid
		) {
			setEvaluationErrors((prev) => ({
				...prev,
				[res?.isExpressionsValid?.key]: res?.isExpressionsValid?.message,
			}));
			toast.error('API testing failed due to invalid expressions');
		}
		setTimeout(() => {
			setIsLoading(false);
		}, 100);
	};

	const testApiMutation = useMutation({
		mutationFn: testApiV2,
		onSuccess: async (res) => {
			console.log(res, 'test-run ');
			handleTestRunSuccess(res);
		},
		onError: (err) => {
			handleErrorMessage(err);
			setFormFields((prev) => ({
				...prev,
				isApiTestDone: true,
				isApiSchemaValid: false,
			}));
			console.log('on error triggered');
			setIsLoading(false);
		},
	});

	const formatPayload = (payload: IAPIFormFields['apiPayload']) => {
		const tempPayload = {};
		payload.forEach((item) => {
			tempPayload[item.value] = item.testValue;
		});

		return tempPayload;
	};
	const formatCustomPayload = (
		payload: IAPIFormFields['apiConstantDataFields'],
	) => {
		const tempPayload = {};
		payload.forEach((item) => {
			tempPayload[item.value] = item.testValue;
		});

		return tempPayload;
	};

	const formatCustomHeaders = (headers: IAPIFormFields['apiHeaders']) => {
		const tempHeaders: any = {};
		headers.forEach((item: any) => {
			tempHeaders[item.key] = item?.value?.trim();
		});

		return tempHeaders;
	};

	const handleTestApi = async () => {
		setFormErrors({});
		const metricExpressions = {
			metric: `return response?.data?.metric`,
			metricDataType: `return response?.data?.metricDataType`,
		};

		await testApiMutation.mutateAsync({
			type: 'custom',
			apiDataFields: formatPayload(formFields.apiPayload),
			apiDataPassingMethod: formFields.apiPayloadMethod?.toUpperCase(),
			apiEndpoint: formFields.apiEndpoint,
			apiHeaders: formFields?.isHeaderRequired
				? formatCustomHeaders(formFields.apiHeaders)
				: {},
			apiMethod: formFields.apiMethod?.toUpperCase(),
			apiName: formFields.apiName,
			apiConstantDataFields: formatCustomPayload(
				formFields.apiConstantDataFields,
			),
			apiOutputExpressions: {
				...formFields.apiOutputExpressions,
				metric: {
					expression: formFields?.apiOutputExpressions?.metric?.expression,
				},
				metricDataType: {
					expression:
						formFields?.apiOutputExpressions?.metricDataType?.expression,
				},
			},
			isMetricBasedTaskEnabled: formFields?.isMetricBasedTaskEnabled,
			graphQlQuery:
				formFields?.apiCredentialsType === ApiCredentialTypes.GraphQl
					? formFields?.graphQlQuery
					: null,
			apiCredentialsType: formFields.apiCredentialsType,
		});
	};

	const handlePayloadInputTestValue = (newVal: string | Date, index: number) => {
		// setStep(CreateTaskAPISteps.Testing);
		const tempApiPayload = [...(formFields?.apiPayload ?? [])];
		tempApiPayload[index] = {
			...tempApiPayload[index],
			testValue: newVal,
		};
		setFormFields((prev) => ({
			...prev,
			apiPayload: tempApiPayload,
			isApiTestDone: false,
		}));
	};

	return {
		handleTestApi,
		handlePayloadInputTestValue,
		isTestRunning: testApiMutation.isPending,
	};
};

export default useTestAPI;
