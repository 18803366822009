import * as React from 'react';
import { cn } from '@/lib/utils';
import {
	NavigationMenu,
	NavigationMenuContent,
	NavigationMenuItem,
	NavigationMenuLink,
	NavigationMenuList,
	NavigationMenuTrigger,
} from '@/components/ui/navigation-menu';
import { Link } from 'react-router-dom';
import { links } from '@/config/links';

export function DashboardTabs() {
	return (
		<NavigationMenu>
			<NavigationMenuList>
				<NavigationMenuItem>
					<NavigationMenuTrigger className="text-sm pb-1 px-3 text-muted-foreground transition-colors hover:text-primary">
						Help
					</NavigationMenuTrigger>
					<NavigationMenuContent className="">
						<ul className="flex flex-col p-2 pb-1 md:w-[180px] lg:w-[180px]">
							<li
								className="mb-2 text-black font-base text-sm cursor-pointer hover:bg-muted p-1 rounded-sm"
								onClick={() =>
									window.open(links?.docs?.campaigns, '_blank')
								}
							>
								<i className="bi bi-compass mr-2"></i>
								Creating Quests
							</li>
							<li
								className="mb-2 text-black font-base text-sm cursor-pointer  hover:bg-muted p-1 rounded-sm"
								onClick={() =>
									window.open(links?.docs?.dave, '_blank')
								}
							>
								<i className="bi bi-cloud-fog2 mr-2"></i>
								DAVE
							</li>
							<li
								className="mb-2 text-black font-base text-sm cursor-pointer  hover:bg-muted p-1 rounded-sm"
								onClick={() =>
									window.open(links?.docs?.nfts, '_blank')
								}
							>
								<i className="bi bi-code-square mr-2"></i>
								NFT Contracts
							</li>
							{/* <Separator /> */}
							<li
								className="mb-2 text-black font-base text-sm cursor-pointer  hover:bg-muted p-1 rounded-sm"
								onClick={() =>
									window.open(links?.docs?.taskAPIs, '_blank')
								}
							>
								<i className="bi bi-plug mr-2"></i>Integrating APIs
							</li>
						</ul>
					</NavigationMenuContent>
				</NavigationMenuItem>
			</NavigationMenuList>
			<Link
				to={links?.productGuide}
				className="text-sm pt-1 px-2 text-muted-foreground transition-colors hover:text-primary group inline-flex items-center"
				target="_blank"
				rel="noopener noreferrer"
			>
				Product Guide
				<i className="bi-arrow-up-right-circle ms-2 text-primary text-xs icon-transition"></i>
			</Link>
			<Link
				to={links?.featureRequest}
				className="text-sm pt-1 px-2 text-muted-foreground transition-colors hover:text-primary group inline-flex items-center"
				target="_blank"
				rel="noopener noreferrer"
			>
				Feature Requests
				<i className="bi-arrow-up-right-circle ms-2 text-primary text-xs icon-transition"></i>
			</Link>
		</NavigationMenu>
	);
}

const ListItem = React.forwardRef<
	React.ElementRef<'a'>,
	React.ComponentPropsWithoutRef<'a'>
>(({ className, title, children, ...props }, ref) => {
	return (
		<li>
			<NavigationMenuLink asChild>
				<a
					ref={ref}
					className={cn(
						'block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
						className,
					)}
					{...props}
				>
					<div className="text-sm font-medium leading-none">{title}</div>
					<p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
						{children}
					</p>
				</a>
			</NavigationMenuLink>
		</li>
	);
});
ListItem.displayName = 'ListItem';
