import { getGASessionId } from '@/utils/ga';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function getTimezone() {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const adjustedIntercomLauncherPosition = (position: string) => {
	const intercomEle = document.getElementsByClassName(
		'intercom-lightweight-app-launcher',
	);
	if (intercomEle.length > 0) {
		const ele = intercomEle[0];
		(ele as any).style.bottom = position;
		console.log('Re-adjusted Intercom launcher position');
	}
};

export const getUtmParams = async () => {
	const search = new URLSearchParams(location.search);
	const referrer = document.referrer || 'DIRECT';

	let sessionId: string | null;
	try {
		sessionId = await getGASessionId(3, 300);
	} catch (error) {
		console.error('Error fetching session ID:', error);
		sessionId = null;
	}

	const params: Record<string, string> = { referrer, sessionId: sessionId || '' };

	for (const [key, value] of search.entries()) {
		const normalizedKey = key
			.toLowerCase()
			.replace(/_(.)/g, (_, char) => char.toUpperCase());
		params[normalizedKey] = value;
	}

	const commonUtmParams = [
		'utmSource',
		'utmMedium',
		'utmCampaign',
		'utmTerm',
		'utmContent',
		'utmId',
	];

	const filteredParams = {
		referrer: params.referrer,
		sessionId: params.sessionId,
		...commonUtmParams.reduce(
			(acc, param) => {
				if (params[param] !== undefined && params[param] !== null) {
					acc[param] = params[param];
				}
				return acc;
			},
			{} as Record<string, string>,
		),
	};

	return filteredParams;
};

export const removeUtmParams = () => {
	const url = new URL(window.location.href);
	const paramsToDelete = [];

	url.searchParams.forEach((value, key) => {
		// console.log(`Processing param: ${key}`);
		if (key.toLowerCase().startsWith('utm_')) {
			// console.log(`Will remove param: ${key}`);
			paramsToDelete.push(key);
		}
	});

	// Remove UTM parameters
	paramsToDelete.forEach((key) => {
		console.log(`Removing param: ${key}`);
		url.searchParams.delete(key);
	});

	// Update the URL without reloading the page
	window.history.replaceState({}, document.title, url.pathname + url.search);
};

export const getUtmUtil = async () => {
	const utmParams = await getUtmParams();
	const gaSessionId = await getGASessionId(3, 300); // Await GA session retrieval once

	const requiredUtmKeys = [
		'utmSource',
		'utmMedium',
		'utmCampaign',
		'utmTerm',
		'utmContent',
		'utmId',
	];

	const hasUtmParams = requiredUtmKeys.some((key) => key in utmParams);

	if (!hasUtmParams) {
		// No UTM params in URL, fallback to localStorage
		const storedUtmParams = JSON.parse(
			localStorage.getItem('utmParams') || '{}',
		);

		if (Object.keys(storedUtmParams).length > 0) {
			return {
				...storedUtmParams,
				sessionId: gaSessionId || storedUtmParams.sessionId || null,
			};
		}
	}

	// Store UTM params in localStorage for future use
	localStorage.setItem('utmParams', JSON.stringify(utmParams));

	// Return UTM params from URL with the sessionId
	return {
		...utmParams,
		sessionId: gaSessionId || utmParams.sessionId || null,
	};
};
