import { toast } from 'sonner';
import { CampaignRewardCategory, CampaignRewardType } from '../../types';
import { useCreateCampaign } from './useCreateCampaign';
import {
	validateDuration,
	validatePositiveNumber,
	validateString,
} from '@/utils/validation';
import { useEffect } from 'react';
import { useCreateCampaignValidateAdminInputs } from './useCreateCampaignValidateAdminInputs';
import { deepCopy } from '@/utils/parsers';
import { campaignColumns } from '@/features/admin/components/campaigns/CampaignColumns';
import { useGetGlobalConfigs } from '@/features/getting-started/hooks/useGetGlobalConfigs';

const defaultRewardsErrors = {
	discord: {
		roleId: false,
		method: false,
		winners: false,
	},
	token: {
		method: false,
		chain: false,
		token: false,
		winners: false,
		reward: false,
		expected: false,
	},
	nft: {
		nftContract: false,
		winners: false,
	},
	whitelist: {
		name: false,
		image: false,
		winners: false,
	},
};

const defaultDetailsErrors = {
	name: false,
	description: false,
	duration: false,
	bannerFile: false,
	referral: {
		numberOfRequiredReferrals: '',
		rewardType: '',
		rewardValue: '',
	},
};

const defaultTaskErrors = {
	isEmpty: false,
	// [task._id]: {
	// adminInputs: {},
	// description: false,
	// customInitiationURL: false,
	// 	[adminInput.key]: false,
	// },
};

export const useCreateCampaignValidate = () => {
	const { rewards, setErrors, details, errors, tasks } = useCreateCampaign();
	const { validateAdminInputs } = useCreateCampaignValidateAdminInputs();

	const { globalConfigs } = useGetGlobalConfigs();

	useEffect(() => {
		setErrors((prev: any) => ({
			...prev,
			details: { ...deepCopy(defaultDetailsErrors) },
			sections: {
				...prev.sections,
				DETAILS: false,
			},
		}));
	}, [details, setErrors]);

	useEffect(() => {
		setErrors((prev: any) => ({
			...prev,
			rewards: { ...deepCopy(defaultRewardsErrors) },
			sections: {
				...prev.sections,
				REWARDS: false,
			},
		}));
	}, [rewards, setErrors]);

	const validateRewards = () => {
		const activeRewards = rewards?.filter((i) => i.isActive);
		const newErrors = { ...deepCopy(defaultRewardsErrors) };

		const anyRecurranceTask = tasks.some(
			(i) => i.type === 'task' && i.task.recurrenceConfig.isRecurring,
		);

		for (const reward of activeRewards) {
			if (reward.category === CampaignRewardCategory.DiscordRole) {
				if (!validateString(reward?.discordReward?.roleId)) {
					newErrors.discord.roleId = true;
				}
				if (!validateString(reward?.method)) {
					newErrors.discord.method = true;
				}
				if (reward?.method !== CampaignRewardType.Unlimited) {
					if (!validatePositiveNumber(+reward?.numRewards)) {
						newErrors.discord.winners = true;
					}
				}
				if (
					reward?.method === CampaignRewardType.Fcfs &&
					anyRecurranceTask
				) {
					newErrors.discord.method =
						'FCFS is not allowed in quests with recurring tasks';
				}
			} else if (reward.category === CampaignRewardCategory.Token) {
				if (!validateString(reward?.tokenReward?.tokenAddress)) {
					newErrors.token.token = true;
				}
				if (
					!validatePositiveNumber(+reward?.tokenReward?.tokenAmountPerUser)
				) {
					newErrors.token.reward = true;
				}
				if (!validatePositiveNumber(+reward?.tokenReward?.chainId)) {
					newErrors.token.chain = true;
				}
				if (!validatePositiveNumber(+reward?.numRewards)) {
					newErrors.token.winners = true;
				}
				if (
					reward.method === CampaignRewardType.SmartRaffle &&
					!validatePositiveNumber(+reward?.countExpected)
				) {
					newErrors.token.expected = true;
				}
				if (!validateString(reward?.method)) {
					newErrors.token.method = true;
				}
				if (
					reward?.method === CampaignRewardType.Fcfs &&
					anyRecurranceTask
				) {
					newErrors.token.method =
						'FCFS is not allowed in quests with recurring tasks';
				}
			} else if (reward.category === CampaignRewardCategory.Nft) {
				if (!validateString(reward?.nftReward?.nftTokenId)) {
					newErrors.nft.nftContract = true;
				}
				if (reward?.method !== CampaignRewardType.Unlimited) {
					if (!validatePositiveNumber(+reward?.numRewards)) {
						newErrors.nft.winners = true;
					}
				}
				if (
					reward?.method === CampaignRewardType.Fcfs &&
					anyRecurranceTask
				) {
					newErrors.nft.method =
						'FCFS is not allowed in quests with recurring tasks';
				}
			} else if (reward.category === CampaignRewardCategory.Whitelist) {
				if (
					!reward?.whitelistReward?.image ||
					reward?.whitelistReward?.image === ''
				) {
					if (
						!reward?.whitelistReward?.nftImage ||
						reward?.whitelistReward?.nftImage.length === 0
					) {
						newErrors.whitelist.image = true;
					}
				}
				if (!validateString(reward?.whitelistReward?.name)) {
					newErrors.whitelist.name = true;
				}
				if (reward?.method !== CampaignRewardType.Unlimited) {
					if (!validatePositiveNumber(+reward?.numRewards)) {
						newErrors.whitelist.winners = true;
					}
				}
				if (
					reward?.method === CampaignRewardType.Fcfs &&
					anyRecurranceTask
				) {
					newErrors.whitelist.method =
						'FCFS is not allowed in quests with recurring tasks';
				}

				if (
					details.referral?.enableCustomReferralPoints &&
					!details?.referral?.customPoints
				) {
					newErrors.whitelist.customPoints =
						'Please enter a non-zero value for custom points per XP';
				}
			}
		}
		const isValid =
			Object.values(newErrors)
				.map((i) => Object.values(i))
				.flat()
				.filter((i) => i).length === 0;

		setErrors((prev: any) => ({
			...prev,
			rewards: newErrors,
			sections: {
				...prev.sections,
				REWARDS: !isValid,
			},
		}));
		return isValid;
	};

	const hasErrors = (errors) => {
		if (typeof errors !== 'object' || errors === null) {
			return !!errors;
		}
		return Object.values(errors).some((value) => {
			if (typeof value === 'object' && value !== null) {
				return hasErrors(value);
			}
			return !!value;
		});
	};

	const validateDetails = () => {
		const newErrors = { ...deepCopy(defaultDetailsErrors) };
		if (!validateString(details.name)) {
			newErrors.name = true;
		}
		if (!validateString(details.description)) {
			newErrors.description = true;
		}
		if (!validateDuration(details.startDate, details.endDate)) {
			newErrors.duration = true;
		}
		if (!details.bannerFile && !details.bannerLink) {
			newErrors.bannerFile = true;
		}
		if (details.referral.referralTaskEnabled) {
			if (
				!validatePositiveNumber(
					details.referral.numberOfRequiredReferrals,
				) ||
				details.referral.numberOfRequiredReferrals < 1
			) {
				newErrors.referral.numberOfRequiredReferrals =
					'Number of required referrals must be greater than 0';
			}
			if (!details.referral.rewardType) {
				newErrors.referral.rewardType = 'Please select a reward type';
			}
			if (
				!validatePositiveNumber(details.referral.rewardValue) ||
				details.referral.rewardValue < 1
			) {
				newErrors.referral.rewardValue = 'Reward value must be > 0';
			}
			if (globalConfigs.MaxXpInQuestReferral) {
				if (
					details.referral.rewardValue > globalConfigs.MaxXpInQuestReferral
				) {
					newErrors.referral.rewardValue =
						'Reward value cannot exceed the maximum XP in quest referral';
				}
			}
			if (
				!details?.referral?.referralTaskText ||
				!details?.referral?.referralTaskText.trim()
			) {
				newErrors.referral.referralTaskText =
					'Please enter a referral task text';
			}
		}

		const isValid = !hasErrors(newErrors);

		setErrors((prev: any) => ({
			...prev,
			details: newErrors,
			sections: {
				...prev.sections,
				DETAILS: !isValid,
			},
		}));
		return isValid;
	};

	const validateTasks = async () => {
		const newErrors = { ...deepCopy(defaultTaskErrors) };
		if (tasks.length === 0) {
			newErrors.isEmpty = true;
			setErrors((prev: any) => ({
				...prev,
				tasks: newErrors,
				sections: {
					...prev.sections,
					TASKS: true,
				},
			}));
			return false;
		}
		let isValid = true;
		const tasks_ = tasks
			.map((i) => (i.type === 'task' ? i.task : i.tasks))
			.flat();
		for (const task of tasks_) {
			newErrors[task.taskId] = {
				adminInputs: {},
				description: false,
				customInitiationURL: false,
			};
			if (!task.description) {
				newErrors[task.taskId].description = true;
				isValid = false;
			}
			if (task.isCustomInitiationURLAllowed && task.customInitiationURL) {
				const isValidURL = new RegExp(/^(https):\/\/[^ "]+$/).test(
					task.customInitiationURL,
				);
				if (!isValidURL) {
					newErrors[task.taskId].customInitiationURL = true;
					isValid = false;
				}
			}
			const adminInputErrors = await validateAdminInputs(
				task.adminInputs,
				task.templateFamily,
				task.templateType,
			);
			newErrors[task.taskId].adminInputs = adminInputErrors;
			if (Object.values(adminInputErrors).some((i) => i)) {
				isValid = false;
			}
		}
		setErrors((prev: any) => ({
			...prev,
			tasks: newErrors,
			sections: {
				...prev.sections,
				TASKS: !isValid,
			},
		}));
		return isValid;
	};

	const validateEdgeCases = async () => {
		const activeRewards = rewards?.filter((i) => i.isActive);
		const anyRaffleReward =
			activeRewards.length > 0
				? activeRewards.some(
						(i) =>
							i.method === CampaignRewardType.LuckyDraw ||
							i.method === CampaignRewardType.SmartRaffle ||
							i.method === CampaignRewardType.Leaderboard,
					)
				: false;
		if (details.noEndTime && anyRaffleReward) {
			toast.error(
				'Raffle and Leaderboard rewards are not allowed for campaigns without an end time',
				{ duration: 1500 },
			);
			setErrors((prev) => ({
				...prev,
				details: {
					...prev.details,
					duration:
						'Raffle rewards are not allowed for campaigns without an end time',
				},
				sections: {
					...prev.sections,
					DETAILS: true,
				},
			}));
			return false;
		}

		const anyFCFSReward = activeRewards.some(
			(i) => i.method === CampaignRewardType.Fcfs,
		);
		const anyRecurranceTask = tasks.some(
			(i) => i.type === 'task' && i.task.recurrenceConfig.isRecurring,
		);
		if (anyRecurranceTask && anyFCFSReward) {
			toast.error(
				'Recurring tasks are not allowed in campaigns with FCFS rewards',
				{ duration: 1500 },
			);
			return false;
		}
		return true;
	};

	console.log(errors);
	const validate = async () => {
		const isRewardValid = validateRewards();
		const isDetailsValid = validateDetails();
		const isTasksValid = await validateTasks();
		const isEdgeCasesValid = validateEdgeCases();

		if (!(isRewardValid && isDetailsValid && isTasksValid)) {
			toast.error('Please fill in all required fields', { duration: 1500 });
		}
		return isRewardValid && isDetailsValid && isTasksValid && isEdgeCasesValid;
	};

	return {
		validate,
	};
};
