export enum TrackingEvents {
	Login = 'Login',
	PageView = 'PageView',
	Signup = 'Signup',
	Logout = 'Logout',

	UserData = 'UserData',

	SignUpClicked = 'SignUpClicked',

	//onboarding
	OnboardingProfileUpdated = 'OnboardingProfileUpdated', //tw-o8zyu-olvp9
	OnboardingCommunityUpdated = 'OnboardingCommunityUpdated', //tw-o8zyu-olvpa
	OnboardingSocialsUpdated = 'OnboardingSocialsUpdated', //tw-o8zyu-olvpc
	EmailVerified = 'EmailVerified', //tw-o8zyu-olvpe

	OnboardingCommunityUpdatedV2 = 'OnboardingCommunityUpdatedV2', //tw-o8zyu-olvpa

	//quest-create
	CreateQuestViewed = 'CreateQuestViewed',
	CreateQuestActions = 'CreateQuestActions',

	CreateQuestNavigated = 'CreateQuestNavigated',
	CreateQuestSectionViewed = 'CreateQuestSectionViewed',
	CreateQuestToggleMode = 'CreateQuestToggleMode',

	CreateQuestAddTask = 'CreateQuestAddTask',
	CreateQuestReorderTasks = 'CreateQuestReorderTasks',
	CreateQuestDeleteTask = 'CreateQuestDeleteTask',

	//quest-report
	QuestReportSectionViewed = 'QuestReportSectionViewed',
	QuestReportActions = 'QuestReportActions',
	QuestDetailsUpdated = 'QuestDetailsUpdated',
	QuestTaskUpdated = 'QuestTaskUpdated',
	QuestBannerUpdated = 'QuestBannerUpdated',

	//launch
	LaunchQuestViewed = 'LaunchQuestViewed',
	LaunchQuestConfirmed = 'LaunchQuestConfirmed',
	LaunchQuestTokenApproved = 'LaunchQuestTokenApproved',
	LaunchQuestTokenTransferred = 'LaunchQuestTokenTransferred',
	LaunchQuestTokenError = 'LaunchQuestTokenError',
	LaunchQuestCompleted = 'LaunchQuestCompleted',

	//leaderboard
	LeaderboardExportInitiated = 'LeaderboardExportInitiated',
	UserProfileViewed = 'UserProfileViewed',

	//loyalty
	LoyaltyLeaderboardExported = 'LoyaltyLeaderboardExported',

	//homepage
	HomepageSuggestedCardsClicked = 'HomepageSuggestedCardsClicked',
	HomepageQuickActionsClicked = 'HomepageQuickActionsClicked',
	EnterpriseApprovalStatus = 'EnterpriseApprovalStatus',

	//boosters
	BoostersCardClicked = 'BoostersCardClicked',
	BoostersRequestSubmitted = 'BoostersRequestSubmitted',

	//KolMarketplace
	KOLMarketplaceViewed = 'KOLMarketplaceViewed',
	SearchTextEntered = 'SearchTextEntered',
	ChannelsFilterClicked = 'ChannelsFilterClicked',
	SocialChannelClicked = 'SocialChannelClicked',

	//dashboard
	GlobalSearchViewed = 'GlobalSearchViewed',
	GlobalSearchUsed = 'GlobalSearchUsed',
	NotificationsViewed = 'NotificationsViewed',
	NotificationClicked = 'ClickedOnNotification',
	EnterpriseSwitched = 'EnterpriseSwitched',
	ApprovalDialogViewed = 'ApprovalDialogViewed',
	ApprovalDialogActions = 'ApprovalDialogActions',

	//nfts
	DeployNFTViewed = 'DeployNFTViewed',
	DeployNFTCompleted = 'DeployNFTCompleted',

	//dave
	DaveContractAdded = 'DaveContractAdded',
	DaveActionAdded = 'DaveActionAdded',
	DaveQueryAdded = 'DaveQueryAdded',
	DaveTxnHelperUsed = 'DaveTxnHelperUsed',

	//task apis
	TaskAPIsCreateViewed = 'TaskAPIsCreateViewed',
	TaskAPisCreateDetailAdded = 'TaskAPisCreateDetailAdded',
	TaskAPIsCreatePayloadAdded = 'TaskAPIsCreateAdded',
	TaskAPIsCreateTested = 'TaskAPIsCreateTested',
	TaskAPIsCreateSuccessful = 'TaskAPIsCreateSuccessful',

	// events
	EventViewed = 'EventViewed',
	EventCreateAction = 'EventCreateAction',
	EventCreateViewed = 'EventCreateViewed',

	// quest-creation-nudges
	DescriptionProTipViewed = 'DescriptionProTipViewed',
	DurationProTipViewed = 'DurationProTipViewed',
	QuickSummaryLinkClicked = 'QuickSummaryLinkClicked',
	RewardsProTipViewed = 'RewardsProTipViewed',
	ERC20ProTipViewed = 'ERC20ProTipViewed',
	DiscordProTipViewed = 'DiscordProTipViewed',
	OfficialPointsProTipViewed = 'OfficialPointsProTipViewed',
	CustomRewardsProTipViewed = 'CustomRewardsProTipViewed',
	NextTipClicked = 'NextTipClicked',
	PreviousTipClicked = 'PreviousTipClicked',
	TipsClosed = 'TipsClosed',
	BestPracticesClicked = 'BestPracticesClicked',
}

export enum TrackingDimensions {
	userId,

	//routes
	pageName,
	title,
	search,

	//auth
	email,
	name,
	createdAt,
	avatar,
	totalEnterprises,
	enterpriseId,
	enterpriseName,
	loginMethod,

	//sources
	page,

	//events,
	eventId,
	eventTitle,
	eventStatus,
	isUpdate,
	action,

	//taskapis
	apiId,
	apiName,
	isValid,
	// isUpdate,

	//dave
	contractAddress,
	isBlockExplorerAvailable,
	actionId,
	numConditions,
	txnHash,
	chainId,
	responseTime,

	//boosters
	booster,
	kolId,
	kolName,
	projectId,
	projectName,
	kolLanguage,
	kolFollowers,
	kolChannels,

	//leaderboard
	resourceType,
	questUserId,
	userRank,
	username,
	totalXp,

	//dashbaord
	isShortcut,
	readAll,
	link,
	newEnterpriseId,

	//dashboard
	cardTitle,
	cardLink,
	resourceId,

	//campaign
	campaignId,
	campaignName,
	actionType,
	state,
	mode,
	section,
	templateFamily,
	templateType,
	selectedTab,
	isTokenReward,
	isTxnRequired,
	campaignStatus,
	preApproved,

	//enterprise approval
	isApproved,
}
