import { ICampaignAddReward } from '@/features/campaigns/types';
import InputText from '@/components/element/inputs/InputText';
import { useMemo } from 'react';
import { formatNumberWithCommas } from '@/utils/parsers';

export const TokenRewardWinners = ({
	tokenReward,
	setTokenReward,
	errors,
}: {
	tokenReward: ICampaignAddReward;
	setTokenReward: (reward: ICampaignAddReward) => void;
	errors: {
		method: boolean;
		chain: boolean;
		token: boolean;
		winners: boolean;
		reward: boolean;
		expected: boolean;
	};
}) => {
	return (
		<div className="mt-5 mb-5 px-6">
			<div className="grid grid-cols-2 gap-2 ">
				<div>
					<InputText
						label="Number of winners"
						placeholder="1000"
						value={tokenReward?.numRewards}
						setValue={(e) =>
							setTokenReward({
								...tokenReward,
								numRewards:
									e && !Number.isNaN(e)
										? parseInt(e?.toString())
										: 0,
							})
						}
						error={errors?.winners}
						errorText="Please enter a number of winners"
						type="number"
					/>
				</div>
				<div>
					<InputText
						label="Reward per winner"
						placeholder="10"
						value={tokenReward?.tokenReward?.tokenAmountPerUser}
						setValue={(e) =>
							setTokenReward({
								...tokenReward,
								tokenReward: {
									...tokenReward.tokenReward,
									tokenAmountPerUser: e?.trim(),
								},
							})
						}
						error={errors?.reward}
						errorText="Please enter a reward per winner"
					/>
				</div>
			</div>
		</div>
	);
};
