import { FacetedFilter } from '@/components/element/inputs/FacetedFilter';
import InputText from '@/components/element/inputs/InputText';
import { useAuth } from '@/hooks/useAuth';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import React, { useEffect, useRef } from 'react';

const KOLFilters = ({
	search,
	filters,
	setSearch,
	setFilters,
}: {
	search: string;
	filters: {
		language: string[];
		region: string[];
		channels: string[];
		price: string[];
	};
	setSearch: React.Dispatch<React.SetStateAction<string>>;
	setFilters: React.Dispatch<React.SetStateAction<any>>;
}) => {
	const { user } = useAuth();
	const previousSearch = useRef(search);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (search.trim() && search !== previousSearch.current) {
				analytics.track(TrackingEvents.SearchTextEntered, {
					userId: user?._id,
					enterpriseId: user?.enterpriseId,
					searchTerm: search,
				});
				previousSearch.current = search;
			}
		}, 1000);

		return () => clearTimeout(timer);
	}, [search, user]);

	const trackingObj = {
		event: TrackingEvents.ChannelsFilterClicked,
		params: {
			userId: user?._id,
			enterpriseId: user?.enterpriseId,
		},
	};

	return (
		<div className="flex items-center space-x-4">
			<InputText
				value={search}
				setValue={(value: string) => setSearch(value)}
				prepend={
					<i className="bi-search px-3 text-muted-foreground text-sm"></i>
				}
				placeholder="Search KOLs"
				className="w-[250px] lg:w-[250px]"
			/>
			<FacetedFilter
				title="Channels"
				options={channelOptions}
				selectedValues={filters.channels}
				setSelectedValues={(values: any) =>
					setFilters((prev) => ({ ...prev, channels: values }))
				}
				trackingObj={trackingObj}
			/>
		</div>
	);
};

const MemoizedKOLFilters = React.memo(KOLFilters);
export default MemoizedKOLFilters;

const channelOptions = [
	{
		value: 'youtube',
		label: 'YouTube',
		icon: 'youtube',
	},
	{
		value: 'discord',
		label: 'Discord',
		icon: 'discord',
	},
	{
		value: 'twitter',
		label: 'Twitter',
		icon: 'twitter',
	},
	{
		value: 'telegram',
		label: 'Telegram',
		icon: 'telegram',
	},
	{
		value: 'tiktok',
		label: 'TikTok',
		icon: 'tiktok',
	},
	{
		value: 'instagram',
		label: 'Instagram',
		icon: 'instagram',
	},
	{
		value: 'facebook',
		label: 'Facebook',
		icon: 'facebook',
	},
	{
		value: 'snapchat',
		label: 'Snapchat',
		icon: 'snapchat',
	},
	{
		value: 'reddit',
		label: 'Reddit',
		icon: 'reddit',
	},
	{
		value: 'linkedin',
		label: 'LinkedIn',
		icon: 'linkedin',
	},
	{
		value: 'substack',
		label: 'Substack',
		icon: 'substack',
	},
	{
		value: 'twitch',
		label: 'Twitch',
		icon: 'twitch',
	},
];
