import { axios } from '@/lib/axios';

export const postGoogleForm = async (data: any) => {
	try {
		await axios.post('/boosters/kols-partnership', data);
	} catch (error) {
		console.log(error);
	}
};

export const getKols = async ({
	limit,
	page,
	search,
	channels,
}: {
	limit: number;
	page: number;
	search?: string;
	channels?: string[];
}) => {
	const searchParam = search ? `&search=${encodeURIComponent(search)}` : '';
	const channelsParam =
		channels && channels.length > 0 ? `&channels=${channels.join(',')}` : '';

	const { data } = await axios.get(
		`/boosters/kols?limit=${limit}&page=${page}${searchParam}${channelsParam}`,
	);
	return data;
};
