import Spinner from '@/components/element/loading/Spinner';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { useCampaignWithdrawFunds } from '@/features/campaigns/hooks/report/useCampaignWithdrawFunds';
import {
	CampaignRewardCategory,
	Status,
	distributionTypeMap,
} from '@/features/campaigns/types';
import { cn } from '@/lib/utils';
import { formatCompactNumber } from '@/utils/parsers';
import { useMemo } from 'react';
import { RewardContent } from './RewardContent';
import { RewardDetailRow } from './RewardDetailRow';

export const RewardCard = ({
	reward,
	campaignStatus,
	isWithdrawn,
	campaignClaimDate,
}) => {
	const {
		isLoading: isWithdrawalInProgress,
		claimTokens,
		txnStatus,
		isClaimable,
	} = useCampaignWithdrawFunds();

	const buttonState = useMemo(() => {
		if (isWithdrawalInProgress) {
			return {
				label: 'Pending',
				icon: '',
				color: 'text-yellow-500',
				bgColor: 'bg-amber-100',
			};
		}
		if (isWithdrawn) {
			return {
				label: 'Refund Successful',
				icon: '',
				color: '',
				bgColor: '',
			};
		}
		if (txnStatus.isFailed) {
			return {
				label: 'Transaction Failed',
				icon: '',
				color: 'text-destructive',
				bgColor: 'bg-red-100',
			};
		}
		if (txnStatus.isSucceeded) {
			return {
				label: 'Refund Successful',
				icon: '',
				color: 'text-green-500',
				bgColor: 'bg-green-100',
			};
		}
		if (txnStatus.isPending) {
			return {
				label: 'Processing Request',
				icon: '',
				color: 'text-yellow-500',
				bgColor: 'bg-amber-100',
			};
		}
		return {
			label: 'Request Refund',
			icon: '',
			color: '',
			bgColor: '',
		};
	}, [isWithdrawalInProgress, txnStatus, isWithdrawn]);

	const showSeparator = useMemo(() => {
		return (
			reward.rewardClaimed != null ||
			reward.rewardWonButNotClaimed != null ||
			reward.rewardLeft != null
		);
	}, [reward]);

	const showRefundButton = useMemo(() => {
		return (
			campaignStatus === Status.Completed &&
			reward.rewardType === CampaignRewardCategory.Token &&
			(reward.rewardLeft > 0 || reward.rewardWonButNotClaimed > 0) // At least one should be non-zero
		);
	}, [
		campaignStatus,
		reward.rewardType,
		reward.rewardLeft,
		reward.rewardWonButNotClaimed,
	]);

	const isRefundAvailable = useMemo(() => {
		const currentDate = new Date();
		const isCampaignComplete = campaignStatus === Status.Completed;
		const isRefundClaimDateValid = currentDate > new Date(campaignClaimDate);
		const isTransactionInactive =
			!txnStatus.isPending && !txnStatus.isSucceeded && !txnStatus.isFailed;

		// console.log({
		// 	currentDate: currentDate,
		// 	campaignClaimDate: campaignClaimDate,
		// 	isCampaignComplete: isCampaignComplete,
		// 	isRefundClaimDateValid: isRefundClaimDateValid,
		// 	isTransactionInactive: isTransactionInactive,
		// 	isWithdrawn: isWithdrawn,
		// 	isWithdrawalInProgress: isWithdrawalInProgress,
		// });

		return (
			isCampaignComplete &&
			isRefundClaimDateValid &&
			!isWithdrawn &&
			!isWithdrawalInProgress &&
			isTransactionInactive
		);
	}, [
		campaignStatus,
		txnStatus,
		isWithdrawalInProgress,
		campaignClaimDate,
		isWithdrawn,
	]);

	return (
		<div className="bg-slate-50 rounded-lg p-4 flex flex-col h-full">
			<div className="flex-grow space-y-4">
				{reward.rewardType !== CampaignRewardCategory.Token && (
					<RewardDetailRow
						label="Reward Name"
						value={reward?.rewardName}
					/>
				)}
				{reward.rewardPool !== null && (
					<RewardDetailRow
						label="Total Reward Pool"
						value={
							<RewardContent
								reward={reward}
								rewardType={reward.rewardType}
								rewardValue={reward.rewardPool}
								noSeparator
							/>
						}
					/>
				)}
				<RewardDetailRow
					label="Distribution Type"
					value={distributionTypeMap[reward.rewardDistributionType]}
					onHoverFullText
				/>
				<RewardDetailRow
					label="Number of winners"
					value={
						reward.numberOfWinners != null ? (
							<span className="flex items-center">
								<i className="bi-people-fill text-slate-600 me-1"></i>
								{formatCompactNumber(reward.numberOfWinners)}
							</span>
						) : null
					}
				/>
				<RewardDetailRow
					label="Reward per user"
					value={
						reward.rewardPerUser != null ? (
							<span className="flex items-center">
								<RewardContent
									reward={reward}
									rewardValue={reward.rewardPerUser}
									rewardType={reward.rewardType}
									noSeparator
								/>
							</span>
						) : null
					}
				/>
				{showSeparator && <Separator />}
				<RewardDetailRow
					label="Claimed"
					value={
						reward.rewardClaimed != null ? (
							<span className="flex items-center">
								<i className="bi-people-fill text-slate-600 me-1"></i>
								{reward.rewardClaimed}
								<RewardContent
									reward={reward}
									rewardValue={reward.rewardClaimed}
									rewardPerUser={reward.rewardPerUser}
									rewardType={reward.rewardType}
								/>
							</span>
						) : null
					}
				/>

				<RewardDetailRow
					label="Won but not claimed"
					value={
						reward.rewardWonButNotClaimed != null ? (
							<span className="flex items-center">
								<i className="bi-people-fill text-slate-600 me-1"></i>
								{reward.rewardWonButNotClaimed}
								<RewardContent
									reward={reward}
									rewardValue={reward.rewardWonButNotClaimed}
									rewardPerUser={reward.rewardPerUser}
									rewardType={reward.rewardType}
								/>
							</span>
						) : null
					}
				/>

				<RewardDetailRow
					label="Unused"
					value={
						reward.rewardLeft != null ? (
							<span className="flex items-center">
								<RewardContent
									reward={reward}
									rewardValue={reward.rewardLeft}
									rewardPerUser={reward.rewardPerUser}
									rewardType={reward.rewardType}
									noSeparator
								/>
							</span>
						) : null
					}
				/>
			</div>

			{showRefundButton && (
				<div className="w-full">
					<Separator className="my-5" />

					<Button
						onClick={claimTokens}
						disabled={!isRefundAvailable}
						className={cn('shadow-none w-full')}
					>
						{isWithdrawalInProgress && <Spinner className="me-2" />}
						{buttonState.label}
					</Button>
				</div>
			)}
		</div>
	);
};
