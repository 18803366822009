import analytics from '@/lib/analytics';
import { queryClient } from '@/lib/react-query';
import { TrackingEvents } from '@/types/tracking.type';
import { isValidEndpoint } from '@/utils/parsers';
import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';
import { createAPI } from '../services/task-apis.service';
import {
	ApiCredentialTypes,
	CreateTaskAPISteps,
	TestingStepMode,
} from '../types/task-apis.enum';
import { IAPIFormFields } from '../types/task-apis.type';
import { useConnectAPI } from './useConnectAPI';
import { useCurlParser } from './useCurlParser';
import useTestAPI from './useTestAPI';

interface TestingStepState {
	isApiTestDone: boolean;
	isAPiSchemaValid: boolean;
}

const useCreateTaskAPI = ({ setOpen }: { setOpen: any }) => {
	const { handleTestApi } = useTestAPI();
	const {
		formFields,
		setFormFields,
		setFormErrors,
		step,
		setStep,
		resetForm,
		setIsLoading,
		parsedData,
		setParsedData,
		evaluationErrors,
		setEvaluationErrors,
		setEvaluatedFields,
		setDialogMaxWidth,
	} = useConnectAPI();

	const getTestingStepMode = ({
		isApiTestDone,
		isAPiSchemaValid,
	}: TestingStepState): TestingStepMode => {
		if (!isApiTestDone) return TestingStepMode.Initial;
		return isAPiSchemaValid
			? TestingStepMode.ReadyToSave
			: TestingStepMode.NeedsFormatting;
	};

	const nextStepMap = {
		[CreateTaskAPISteps.Basic]: CreateTaskAPISteps.Curl,
		[CreateTaskAPISteps.Curl]: CreateTaskAPISteps.Payload,
		[CreateTaskAPISteps.Payload]: CreateTaskAPISteps.Testing,
		[CreateTaskAPISteps.Testing]: (state: TestingStepState) => {
			const mode = getTestingStepMode(state);
			switch (mode) {
				case TestingStepMode.ReadyToSave:
					return CreateTaskAPISteps.ReadyToSave;
				case TestingStepMode.NeedsFormatting:
					return CreateTaskAPISteps.EvaluateResponse;
				default:
					return CreateTaskAPISteps.Testing;
			}
		},
		[CreateTaskAPISteps.EvaluateResponse]: CreateTaskAPISteps.Upsert,
		[CreateTaskAPISteps.Upsert]: CreateTaskAPISteps.Upsert,
		[CreateTaskAPISteps.ReadyToSave]: CreateTaskAPISteps.ReadyToSave,
	} as const;

	const prevStepMap = {
		[CreateTaskAPISteps.Curl]: CreateTaskAPISteps.Basic,
		[CreateTaskAPISteps.Payload]: CreateTaskAPISteps.Curl,
		[CreateTaskAPISteps.Testing]: CreateTaskAPISteps.Payload,
		[CreateTaskAPISteps.EvaluateResponse]: CreateTaskAPISteps.Testing,
		[CreateTaskAPISteps.Upsert]: CreateTaskAPISteps.EvaluateResponse,
		[CreateTaskAPISteps.ReTesting]: CreateTaskAPISteps.ReTesting,
		[CreateTaskAPISteps.ReadyToSave]: CreateTaskAPISteps.Testing,
	};

	const API_STEP_LABELS = {
		[CreateTaskAPISteps.Testing]: (state: TestingStepState) => {
			const mode = getTestingStepMode(state);
			switch (mode) {
				case TestingStepMode.ReadyToSave:
					return 'Save API';
				case TestingStepMode.NeedsFormatting:
					return 'Format API Response';
				default:
					return 'Test API';
			}
		},
		[CreateTaskAPISteps.Curl]: ({ apiCredentialsType, curlCommand }) =>
			apiCredentialsType === ApiCredentialTypes.Rest && !curlCommand
				? 'Skip'
				: 'Next',
		[CreateTaskAPISteps.EvaluateResponse]: 'Test Run',
		[CreateTaskAPISteps.Upsert]: 'Save API',
		[CreateTaskAPISteps.ReadyToSave]: 'Save API',
	} as const;

	const API_STEP_DESCRIPTIONS = {
		[CreateTaskAPISteps.Basic]: 'Add your API details to verify on-chain tasks',
		[CreateTaskAPISteps.Curl]:
			'Add API cURL if available else add details in next step',
		[CreateTaskAPISteps.Payload]: 'Verify that your API credentials are correct',
		[CreateTaskAPISteps.Testing]:
			'Add your payload data to test the API response',
		[CreateTaskAPISteps.ReTesting]:
			'Add your payload data to test the API response',
		[CreateTaskAPISteps.EvaluateResponse]: 'Evaluate the response data',
		[CreateTaskAPISteps.Upsert]: 'Save the API',
		[CreateTaskAPISteps.ReadyToSave]: 'Save the API',
	} as const;

	const getButtonLabel = useMemo(() => {
		const stepLabel = API_STEP_LABELS[step];

		if (typeof stepLabel === 'function') {
			// Handle Testing step specifically
			if (step === CreateTaskAPISteps.Testing) {
				return stepLabel({
					isApiTestDone: formFields?.isApiTestDone,
					isAPiSchemaValid: formFields?.isApiSchemaValid,
				});
			}

			return stepLabel({
				apiCredentialsType: formFields?.apiCredentialsType,
				curlCommand: formFields?.curlCommand,
			});
		}

		return stepLabel ?? 'Next';
	}, [
		step,
		formFields?.apiCredentialsType,
		formFields?.curlCommand,
		formFields?.isApiTestDone,
		formFields?.isApiSchemaValid,
	]);

	const getDescription = useMemo(
		() =>
			API_STEP_DESCRIPTIONS[step] ??
			API_STEP_DESCRIPTIONS[CreateTaskAPISteps.Basic],
		[step],
	);

	const handleStepProgression = (
		currentStep: CreateTaskAPISteps,
		formState: any,
	) => {
		const nextStep = nextStepMap[currentStep];

		if (typeof nextStep === 'function') {
			if (currentStep === CreateTaskAPISteps.Testing) {
				return nextStep({
					isApiTestDone: formState?.isApiTestDone,
					isAPiSchemaValid: formState?.isApiSchemaValid,
				});
			}
			return nextStep(formState);
		}

		return nextStep;
	};

	const { handleParse } = useCurlParser(
		formFields,
		setFormFields,
		parsedData,
		setParsedData,
	);

	const handleStartOver = () => {
		setStep(CreateTaskAPISteps.Basic);
		resetForm();
		setOpen(false);
	};
	const formatPayload = (
		payload: IAPIFormFields['apiPayload'],
		constantDataFields: IAPIFormFields['apiConstantDataFields'],
	) => {
		const constantKeys = new Set(constantDataFields.map((item) => item.key));
		const tempPayload = {};
		payload.forEach((item) => {
			if (!constantKeys.has(item.key)) {
				tempPayload[item.key] = item.value;
			}
		});

		return tempPayload;
	};
	const formatCustomHeaders = (headers: IAPIFormFields['apiHeaders']) => {
		const tempHeaders: any = {};
		headers.forEach((item: any) => {
			tempHeaders[item.key] = item?.value?.trim();
		});

		return tempHeaders;
	};

	const shouldParseCurl = () => {
		return (
			(formFields?.apiCredentialsType === ApiCredentialTypes.Rest &&
				formFields?.curlCommand) ||
			formFields?.apiCredentialsType === ApiCredentialTypes.GraphQl
		);
	};
	const handleClick = async (step_?: CreateTaskAPISteps) => {
		if (step_) {
			return setStep(step_);
		}
		if (!verifyFormFields(step)) return;

		try {
			switch (step) {
				case CreateTaskAPISteps.Curl: {
					if (shouldParseCurl()) {
						const parseSuccess = handleParse((error) => {
							console.log(error);
							setFormErrors((prev) => ({
								...prev,
								curlCommand: 'Error parsing cURL',
							}));
						});
						if (!parseSuccess) return;
					}
					break;
				}

				case CreateTaskAPISteps.Testing:
				case CreateTaskAPISteps.ReTesting: {
					if (!formFields?.isApiTestDone) {
						// Initial testing
						setIsLoading(true);
						setEvaluationErrors({});
						setEvaluatedFields({});
						await handleTestApi();
						return;
					}
					break;
				}

				case CreateTaskAPISteps.EvaluateResponse: {
					setIsLoading(true);
					if (Object.keys(evaluationErrors).length !== 0) {
						return;
					}
					await handleTestApi();
					setStep(CreateTaskAPISteps.Upsert);
					return;
				}

				case CreateTaskAPISteps.ReadyToSave:
				case CreateTaskAPISteps.Upsert: {
					if (formFields?.isApiSchemaValid) {
						setIsLoading(true);
						await createTaskApiMutation.mutateAsync({
							_id: formFields._id,
							apiName: formFields.apiName,
							apiEndpoint: formFields.apiEndpoint,
							apiMethod: formFields.apiMethod?.toUpperCase(),
							apiDataPassingMethod:
								formFields.apiPayloadMethod?.toUpperCase(),
							apiHeaders: formFields?.isHeaderRequired
								? formatCustomHeaders(formFields.apiHeaders)
								: {},
							apiDataFields: formatPayload(
								formFields.apiPayload,
								formFields.apiConstantDataFields,
							),
							apiVerificationFor: formFields.apiVerificationAction,
							isRecurringTaskEnabled:
								formFields.isRecurringTasksEnabled,
							isMetricBasedTaskEnabled:
								formFields.isMetricBasedTaskEnabled,
							apiCredentialsType: formFields.apiCredentialsType,
							apiOutputExpressions: formFields.apiOutputExpressions,
							apiConstantDataFields: formFields.apiConstantDataFields,
							graphQlQuery: formFields.graphQlQuery,
						});
						setFormErrors({});
						return;
					}

					// If needs formatting, proceed to EvaluateResponse
					setStep(CreateTaskAPISteps.EvaluateResponse);
					setFormErrors({});
					return;
				}
			}

			switch (step) {
				case CreateTaskAPISteps.Basic:
					analytics.track(TrackingEvents.TaskAPisCreateDetailAdded, {
						// isUpdate: ,
					});
					break;
				case CreateTaskAPISteps.Payload:
					analytics.track(TrackingEvents.TaskAPIsCreatePayloadAdded, {
						// isUpdate: ,
					});
					break;
			}

			// Handle normal step progression for other steps
			const nextStep = handleStepProgression(step, formFields);
			setStep(nextStep);
			setDialogMaxWidth('35rem');
			setFormErrors({});
		} finally {
			setIsLoading(false);
		}
	};

	const verifyFormFields = (currentStep: CreateTaskAPISteps) => {
		const error: any = {};

		switch (currentStep) {
			case CreateTaskAPISteps.Basic:
				if (!formFields?.apiName || !formFields?.apiName.trim()) {
					error.apiName = `API name is required`;
				}
				break;
			case CreateTaskAPISteps.Curl:
				if (
					formFields?.apiCredentialsType === ApiCredentialTypes.GraphQl &&
					!formFields?.curlCommand
				) {
					error.curlCommand = `Curl command is required for GraphQL queries`;
				}
				break;
			case CreateTaskAPISteps.Payload:
				setFormFields({
					...formFields,
					apiEndpoint: formFields?.apiEndpoint?.trim(),
				});
				if (!formFields?.apiEndpoint || !formFields?.apiEndpoint?.trim()) {
					error.apiEndpoint = 'API endpoint is required';
				}
				if (
					formFields?.apiEndpoint &&
					!isValidEndpoint(formFields?.apiEndpoint?.trim())
				) {
					error.apiEndpoint = `Please enter a valid endpoint. API Endpoint should be https`;
				}
				if (formFields?.apiEndpoint) {
					if (formFields?.apiEndpoint?.includes('?')) {
						error.apiEndpoint = `API endpoint should not contain query params as they will be dynamically added based on user`;
					}
				}
				if (formFields?.isHeaderRequired) {
					formFields?.apiHeaders?.forEach((item) => {
						if (
							(!item.key || !item.key.trim()) &&
							(!item.value || !item.value.trim())
						) {
							error[item.key] = `Please enter header: key and value`;
						} else if (!item.key || !item.key.trim()) {
							error[item.key] = `Please enter the key`;
						} else if (!item.value || !item.value.trim()) {
							error[item.key] = `Please enter the value`;
						}
					});
				}
				if (formFields?.isCustomConstantPayloadRequired) {
					formFields?.apiConstantDataFields?.forEach((item) => {
						if (
							(!item.key || !item.key.trim()) &&
							(!item.value || !item.value.trim())
						) {
							error[item.key] = `Please enter header: key and value`;
						} else if (!item.key || !item.key.trim()) {
							error[item.key] = `Please enter the key`;
						} else if (!item.value) {
							error[item.key] = `Please enter the value`;
						}
					});
				}
				break;
			case CreateTaskAPISteps.Testing:
			case CreateTaskAPISteps.ReTesting:
				formFields?.apiPayload.forEach((item) => {
					if (!item.testValue) {
						error[item.key] = `${item.key} is required`;
					}
				});
				break;

			default:
				return true;
		}
		setFormErrors(error);
		return Object.keys(error).length === 0;
	};

	const createTaskApiMutation = useMutation({
		mutationFn: createAPI,
		onSuccess: async () => {
			handleStartOver();
			analytics.track(TrackingEvents.TaskAPIsCreateSuccessful, {});
			await queryClient.invalidateQueries({ queryKey: ['api-requests'] });
		},
	});

	return {
		handleClick,
		verifyFormFields,
		nextStepMap,
		prevStepMap,
		handleStartOver,
		getButtonLabel,
		getDescription,
	};
};

export default useCreateTaskAPI;
