import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { fetchRewardDetails } from '../../services/campaigns.service';
import { useCampaignReport } from './useCampaignReport';

const useCampaignRewards = () => {
	const { campaign } = useCampaignReport();

	const {
		data = [],
		isLoading,
		error,
	} = useQuery({
		queryKey: ['campaign', campaign?._id, 'rewards'],
		queryFn: () => fetchRewardDetails(campaign?._id),
		enabled: !!campaign?._id,
	});

	const [currentIndex, setCurrentIndex] = useState(0);

	const currentReward = useMemo(
		() => (Array.isArray(data) && data.length > 0 ? data[currentIndex] : null),
		[data, currentIndex],
	);

	const handleNext = useCallback(() => {
		if (data.length > 0) {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
		}
	}, [data.length]);

	const handlePrev = useCallback(() => {
		if (data.length > 0) {
			setCurrentIndex(
				(prevIndex) => (prevIndex - 1 + data.length) % data.length,
			);
		}
	}, [data.length]);

	return {
		rewardDetails: data,
		isLoading,
		error,
		currentReward,
		handleNext,
		handlePrev,
		currentIndex,
		campaignStatus: campaign?.status,
		campaignClaimDate: campaign?.claimDate,
		isWithdrawn: campaign?.tokenRewardWithdrawal?.isWithdrawn,
	};
};

export default useCampaignRewards;
