import notificationsImg from '@/assets/empty/light/notifications.png';
import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useNotifications } from '@/features/dashboard/hooks/useNotifications';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import NotificationList from './NotificationList';
import { cn } from '@/lib/utils';
import styles from './notificationBell.module.css';

const Notifications = () => {
	const { unreadNotifications, handleReadAllNotifications, notifications } =
		useNotifications();
	return (
		<DropdownMenu
			onOpenChange={() => {
				analytics.track(TrackingEvents.NotificationsViewed, {});
			}}
		>
			<DropdownMenuTrigger asChild>
				<Button size="icon" variant="ghost" className="rounded-full  me-3">
					<div
						className={cn(
							'bi-bell text-lg text-muted-foreground relative',
							unreadNotifications > 0 && [
								styles.bell_ring,
								styles.bell_ring_twice,
							],
						)}
					>
						{unreadNotifications > 0 && (
							<span className="absolute -top-2 -end-2 bg-destructive text-white text-[10px]  rounded-full h-4 w-4 flex items-center justify-center ">
								{unreadNotifications}
							</span>
						)}
					</div>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-[400px]" align="end" forceMount>
				<div className="p-4 flex justify-between shadow rounded">
					<div className="text-md font-medium leading-none">
						Notifications
					</div>
					<p
						className="text-xs leading-none text-muted-foreground hover:underline text-violet-600 font-medium cursor-pointer"
						onClick={() => handleReadAllNotifications()}
					>
						Mark all as read
					</p>
				</div>
				<div className="max-h-[450px] min-h-[450px]  overflow-auto">
					<DropdownMenuSeparator />
					<NotificationList />
					{!notifications ||
						(notifications?.length === 0 && (
							<div>
								<div className="p-4 text-center flex flex-col justify-center items-center min-h-[350px] ">
									<img
										src={notificationsImg}
										className="w-[200px]"
									/>
									<div className="text-sm font-medium">
										Currently, Nothing to report
									</div>
									<div className="text-xs mt-1 text-muted-foreground">
										This area will light up with new
										notifications once there's activity in your
										workplaces.
									</div>
								</div>
							</div>
						))}
				</div>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default Notifications;
