import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import {
	getUser,
	loginWithEmailAndPassword,
	logout,
} from '@/features/auth/services/auth.service';
import analytics from '@/lib/analytics';
import { queryClient } from '@/lib/react-query';
import { TrackingEvents } from '@/types/tracking.type';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';

export interface UserType {
	_id: string;
	name: string;
	enterpriseId: string;
	enterpriseName: string;
	enterpriseLogo: string;
	enterpriseSlug: string;
	enterprises: {
		_id: string;
		name: string;
		logo: string;
		isApproved: boolean;
	}[];
	role: string;
	email: string;
	userAddress: string;
	avatar: string;
	isEnterpriseCreated: boolean;
	isEmailVerified: boolean;
	createdAt?: Date;
	googleId?: string;
	discordId?: string;
	telegramId?: string;
	referralCode: string;
	onboardingStatus: {
		isEmailVerified: boolean;
		isProfileCompleted: boolean;
		isSocialsConnected: boolean;
		isCommunityCompleted: boolean;
	};
	integrationStatus: {
		discord: boolean;
		telegram: boolean;
	};
	primaryChainId: number;
	isIntractAdmin: boolean;
	onboarding: {
		source: string;
		role: string;
	};
}

interface AuthContextType {
	user: UserType | null;
	login: (email: string, password: string) => Promise<UserType>;
	logout: () => void;
	updateUser: (user: UserType) => void;
	refetchUser: () => Promise<void>;
	isLoading: boolean;
	isError: boolean;
	isLogging: boolean;
	setUser: Dispatch<SetStateAction<UserType | null>>;
	showAdminPanel: boolean;
	toggleAdminPanel: () => void;
}

export const AuthContext = createContext<AuthContextType>({
	user: null,
	login: async () => {
		return {} as UserType;
	},
	logout: () => {},
	refetchUser: async () => {},
	updateUser: async () => {},
	isLoading: false,
	isError: false,
	isLogging: false,
	setUser: () => {},
	showAdminPanel: false,
	toggleAdminPanel: () => {},
});

export default function AuthProvider({ children }: { children: JSX.Element }) {
	const [user, setUser] = useState<UserType | null>(null);
	const [showAdminPanel, setShowAdminPanel] = useState(false);

	const userQuery = useQuery<UserType>({
		queryKey: ['user'],
		queryFn: getUser,
	});

	useEffect(() => {
		if (window?.Intercom) {
			window.Intercom('shutdown');
		}
		if (userQuery.data) {
			setUser(userQuery.data);

			analytics.identify(userQuery.data?._id, {
				email: userQuery?.data?.email,
				name: userQuery?.data?.name,
				createdAt: userQuery?.data?.createdAt,
				avatar: userQuery?.data?.avatar,
				enterpriseId: userQuery?.data?.enterpriseId,
				enterpriseName: userQuery?.data?.enterpriseName,
				totalEnterprises: userQuery?.data?.enterprises?.length,
			});

			window.Intercom('boot', {
				api_base: 'https://api-iam.intercom.io',
				app_id: 'egdyqunc',
				name:
					userQuery?.data?.name?.length > 25
						? userQuery?.data?.name?.slice(0, 25) + '...'
						: userQuery?.data?.name,
				email: userQuery?.data?.email,
				created_at: userQuery?.data?.createdAt,
				avatar: userQuery?.data?.avatar,
				enterpriseId: userQuery?.data?.enterpriseId,
				enterpriseName: userQuery?.data?.enterpriseName,
			});
			return;
		}

		window.Intercom('boot', {
			api_base: 'https://api-iam.intercom.io',
			app_id: 'egdyqunc',
		});
	}, [userQuery.data]);

	const loginMutation = useMutation({
		mutationFn: loginWithEmailAndPassword,
		onSuccess: async (data: UserType) => {
			setUser(data);
		},
	});

	const login = async (email: string, password: string): Promise<UserType> => {
		try {
			const account = await loginMutation.mutateAsync({
				email: email.trim().toLowerCase(),
				password,
			});
			return account;
		} catch (err) {
			console.log(err);
			throw err;
		}
	};
	const logoutFn = async () => {
		analytics.track(TrackingEvents.Logout, {});
		await logout();
		// analytics.reset();
		window.location.reload();
	};

	const updateUser = async (user: UserType) => {
		// console.log(user);

		setUser(user);
		await queryClient.setQueryData(['user'], user);
	};

	const refetchUser = async () => {
		await userQuery.refetch();
	};
	const isLoading = false;
	const isError = false;

	if (userQuery.isLoading) {
		return (
			<div>
				<FullPageLoading />
			</div>
		);
	}
	return (
		<AuthContext.Provider
			value={{
				user: user ? user : userQuery.data,
				login,
				logout: logoutFn,
				updateUser,
				refetchUser,
				isLoading,
				isError,
				isLogging: false,
				setUser,
				showAdminPanel,
				toggleAdminPanel: () => {
					if (!user) return;
					if (!user.isIntractAdmin) return;
					setShowAdminPanel((p) => !p);
				},
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}
