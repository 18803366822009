import { Button } from '@/components/ui/button';
import {
	CommandDialog,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from '@/components/ui/command';
import { Separator } from '@/components/ui/separator';
import { useTaskTemplates } from '@/features/campaigns/hooks/create/useTaskTemplates';
import { TaskKeys, TemplateFamily, TemplateType } from '@/features/campaigns/types';
import { cn } from '@/lib/utils';
import { deepCopy } from '@/utils/parsers';
import { useMemo, useState } from 'react';

export function SearchTaskTemplates({
	open,
	setOpen,
	sectionId,
	addTask,
}: {
	setOpen: any;
	open: any;
	sectionId?: string;
	addTask: any;
}) {
	const { templates, families } = useTaskTemplates();
	const [selectedFamily, setSelectedFamily] = useState('');
	const [search, setSearch] = useState('');

	const filteredTemplates = useMemo(() => {
		return templates
			?.filter((templateGroup) => {
				// Check if the template group matches the selected family
				const matchesFamily = selectedFamily
					? selectedFamily === TemplateFamily?.MostFrequent
						? templateGroup.templates.some(
								(template) => template.mostFrequent,
							)
						: templateGroup.family === selectedFamily
					: true;

				if (!matchesFamily) return false;

				const filteredTasks = templateGroup.templates?.filter((task) => {
					const matchesMostFrequentTask =
						selectedFamily === TemplateFamily?.MostFrequent
							? task.mostFrequent
							: true;

					const matchesSearch = search
						? task.tags?.some((tag) =>
								tag.toLowerCase().includes(search.toLowerCase()),
							) ||
							task.name.toLowerCase().includes(search.toLowerCase()) ||
							task.description
								.toLowerCase()
								.includes(search.toLowerCase())
						: true;

					return matchesMostFrequentTask && matchesSearch;
				});

				templateGroup.filteredTemplates = filteredTasks;
				return filteredTasks.length > 0;
			})
			.map((templateGroup) => ({
				...templateGroup,
				templates: templateGroup.filteredTemplates,
			}));
	}, [templates, selectedFamily, search]);

	return (
		<CommandDialog
			open={open}
			onOpenChange={setOpen}
			dialogContentClassName={'sm:max-w-[65rem] sm:max-h-[50rem]'}
		>
			<CommandInput
				placeholder="Search from our Task Library"
				value={search}
				onValueChange={(e) => setSearch(e)}
			/>
			<div className="flex px-0 p-0">
				<div className="w-[25%] border-r h-full overflow-auto ">
					<p className="text-sm text-center font-medium p-2 text-muted-foreground">
						Categories
					</p>
					<Separator />
					<Families
						families={families}
						onSelect={(family) => {
							setSelectedFamily(family);
						}}
						selected={selectedFamily}
					/>
				</div>
				<div className="w-[75%] p-4 h-full overflow-auto">
					<CommandList className="text-xs max-h-[480px]">
						<CommandEmpty>No results found.</CommandEmpty>
						{filteredTemplates?.map((i) => (
							<CommandGroup key={i.group} heading={i.group}>
								<div className="flex flex-wrap gap-4">
									{i.templates.map((j) => (
										<CommandItem
											key={j.name}
											onSelect={() => {
												if (
													j.disabled &&
													j.name.includes('telegram')
												) {
													window.open(
														`/app/integrations?tab=telegram`,
														'_blank',
													);
												} else {
													addTask(
														deepCopy(j),
														i.icon,
														sectionId,
													);
													setOpen(false);
												}
											}}
											value={j.name}
											className={cn(
												'w-[48%] flex items-start gap-3 cursor-pointer',
												{ ' cursor-help': j.disabled },
											)}
										>
											<div className="min-w-10 h-10 border rounded-full flex items-center justify-center me-3 bg-slate-50 relative">
												{j?.logo?.type === 'icon' && (
													<i
														className={` ${j.logo?.value}  ${i.color} text-xl`}
													/>
												)}
												{j.templateType ===
												TemplateType.IntractPersona ? (
													<div className="absolute -bottom-3 rounded-xl py-0.5 px-2 flex bg-violet-500 text-white text-[10px] leading-normal font-normal items-end">
														New
													</div>
												) : null}
											</div>

											<div>
												<div className="font-medium">
													{j.name}
												</div>
												<div className="text-xs text-muted-foreground mt-1">
													{j.disabled
														? 'Please integrate Telegram to use this task'
														: j.adminHelper}
												</div>
											</div>
										</CommandItem>
									))}
								</div>
							</CommandGroup>
						))}
						<CommandSeparator className="mt-4" />
					</CommandList>
				</div>
			</div>
		</CommandDialog>
	);
}

const Families = ({
	families,
	onSelect,
	selected,
}: {
	families: {
		label: string;
		value: string;
		icon: string;
		color: string;
	}[];
	onSelect: (family: string) => void;
	selected: string;
}) => {
	return (
		<div className="flex flex-col px-4 mt-4 max-h-[500px] min-h-[450px] overflow-auto">
			{families.map((i) => (
				<Button
					key={i.value}
					className="rounded-md mb-2 min-h-9 relative" // make button container relative for absolute positioning
					size="sm"
					variant={selected === i.value ? 'default' : 'outline'}
					onClick={() => onSelect(selected === i.value ? '' : i.value)}
				>
					<div className="flex items-center justify-center w-full">
						<i className={`bi-${i.icon} ${i.color} me-2`} />
						{i.label}
					</div>
					{i.value === TemplateFamily?.POH ? (
						<div className="absolute right-3 top-1/2 transform -translate-y-1/2 rounded-xl py-0.5 px-2 flex bg-violet-500 text-white text-[10px] leading-normal font-normal">
							New
						</div>
					) : null}
				</Button>
			))}
		</div>
	);
};
