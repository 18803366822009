import { useAuth } from '@/hooks/useAuth';
import { Button } from '@/components/ui/button';
import VerifyProjectsDialog from '../verify/VerifyProject';
import { Progress } from '@/components/ui/progress';
import EnterpriseApprovalProcess from '../verify/EnterpriseApprovalProcess';
import { useGetEnterprise } from '../../hooks/useGetEnterprise';
import { EnterpriseApprovalStage } from '../../types/enterprise.types';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { SITE_VERSION } from '@/config';

export const BottomNavSection = ({
	openVerifyProjects,
	setOpenVerifyProjects,
	showApprovalCompletionDialog,
	setShowApprovalCompletionDialog,
}) => {
	const { user, toggleAdminPanel, showAdminPanel } = useAuth();
	const { enterprise } = useGetEnterprise();
	return (
		<>
			<div className="mt-auto">
				{!user?.isIntractAdmin && (
					<CompleteOnboarding
						setShowApprovalCompletionDialog={
							setShowApprovalCompletionDialog
						}
						enterprise={enterprise}
					/>
				)}
				{/* )} */}
				{/* {enterprise?.isApproved === false && !user?.isIntractAdmin && (
					<div className="border p-4 m-3 rounded-md bg-slate-100 dark:bg-slate-900 relative">
						<div className="text-xs font-medium">
							Your account is pending approval.
						</div>
						<Button
							className="p-0 mt-1 h-auto text-xs text-muted-foreground"
							variant="link"
							onClick={() => {
								analytics.track(
									TrackingEvents.ApprovalDialogViewed,
									{},
								);
								setOpenVerifyProjects(true);
							}}
						>
							Learn more
						</Button>
					</div>
				)} */}
				{user?.isIntractAdmin && (
					<div className=" px-4 pb-4">
						<Button
							className="w-full"
							variant="outline"
							onClick={() => toggleAdminPanel()}
						>
							<i className="bi bi-gear me-2"></i>
							<span>{`${showAdminPanel ? 'Switch to Normal View' : 'Shift to Admin Panel'}`}</span>
						</Button>
					</div>
				)}
				<div className="space-y-4 p-4 border-t pb-5  text-center">
					<div className="text-xs text-muted-foreground">
						2024 Intract. Dashboard v{SITE_VERSION}
					</div>
				</div>
			</div>
			<VerifyProjectsDialog
				open={openVerifyProjects}
				setOpen={setOpenVerifyProjects}
			/>
			<EnterpriseApprovalProcess
				open={showApprovalCompletionDialog}
				setOpen={setShowApprovalCompletionDialog}
				enterpriseDetails={enterprise}
			/>
		</>
	);
};

const CompleteOnboarding = ({ setShowApprovalCompletionDialog, enterprise }) => {
	const currentStep = stageToStep[enterprise?.approvalStage] || 0;

	const getActionLabel = () => {
		let label = '';
		if (
			enterprise?.isApproved &&
			enterprise?.approvalStage !== EnterpriseApprovalStage.Completed
		) {
			label = 'Complete your profile';
		} else if (
			enterprise?.isApproved &&
			enterprise?.approvalStage === EnterpriseApprovalStage.Completed
		) {
			label = 'Approval Completed';
		} else if (
			!enterprise?.isApproved &&
			enterprise?.approvalStage === EnterpriseApprovalStage.Completed
		) {
			label = 'Approval Pending';
		} else {
			label = 'Claim free Intract Credits!';
		}
		return label;
	};

	const isApprovalCompleted = getActionLabel() === 'Approval Completed';

	return (
		<div
			onClick={() => {
				isApprovalCompleted ? null : setShowApprovalCompletionDialog(true);
				analytics.track(TrackingEvents.ApprovalDialogViewed, {});
			}}
			className={`border block p-4 m-3 rounded-md bg-white dark:bg-slate-900 relative ${isApprovalCompleted ? '' : 'hover:bg-zinc-100'} cursor-pointer transition-all duration-200`}
		>
			<div className="flex justify-between items-center">
				<div className="text-xs font-semibold">
					{getActionLabel()}
					{isApprovalCompleted ? (
						<i className="bi bi-patch-check-fill text-green-500 ms-2"></i>
					) : null}
				</div>
				{isApprovalCompleted ? null : (
					<i className={`bi-chevron-right text-xs cursor-pointer`}></i>
				)}
			</div>
			<div className="mt-3 flex gap-1 items-center">
				<span className="text-xs me-2">{`${currentStep}/3`}</span>
				{[...Array(3)].map((_, index) => (
					<Progress
						key={index}
						className={index < currentStep ? 'bg-violet-400' : ''}
					/>
				))}
			</div>
		</div>
	);
};

const stageToStep = {
	[EnterpriseApprovalStage.AccountDetails]: 0,
	[EnterpriseApprovalStage.CommunityDetails]: 1,
	[EnterpriseApprovalStage.CommunitySocials]: 2,
	[EnterpriseApprovalStage.Completed]: 3,
};
