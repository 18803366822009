export const dashboardNav: {
	title?: string;
	nav: {
		icon: string;
		href: string;
		label: string;
		isActive?: boolean;
		options?: {
			icon: string;
			href: string;
			label: string;
			isActive?: boolean;
		}[];
		badge?: {
			variant: 'default' | 'secondary' | 'destructive' | 'outline';
			text: string;
		};
		isNew?: boolean;
	}[];
}[] = [
	{
		title: '',
		nav: [
			// {
			// 	icon: 'chevron-double-right',
			// 	href: '/app/getting-started',
			// 	label: 'Getting Started',
			// },
			{
				icon: 'house',
				href: '/app/home',
				label: 'Home',
			},
		],
	},
	{
		title: 'Community Hub',
		nav: [
			{
				icon: 'compass',
				href: '/app/campaign/quests',
				label: 'Quests',
			},
			{
				icon: 'people',
				href: '/app/leaderboard',
				label: 'Leaderboard',
			},
			{
				icon: 'heart',
				href: '/app/campaign/loyalty',
				label: 'Loyalty Program',
			},
			{
				icon: 'calendar2-event',
				href: '/app/campaign/events',
				label: 'Events',
			},
			{
				icon: 'shop',
				href: '/app/campaign/reward-store',
				label: 'Reward Store',
			},
			{
				icon: 'gear',
				href: '/app/community-settings/profile',
				label: 'Community Settings',
			},
		],
	},
	{
		title: 'Tools',
		nav: [
			{
				icon: 'plug',
				href: '/app/tools/task-apis',
				label: 'Task APIs',
			},
			{
				icon: 'cloud-fog2',
				href: '/app/tools/dave/actions',
				label: 'No Code Verification',
			},
			{
				href: '/app/tools/auth-tokens',
				icon: 'cpu',
				label: 'Auth Tokens',
			},
			{
				icon: 'chevron-bar-contract',
				href: '/app/tools/your-contracts',
				label: 'Your Contracts',
				options: [
					{
						label: 'NFT Contracts',
						href: '/app/tools/your-contracts/nfts',
						icon: 'bi-currency-bitcoin',
					},
					{
						label: 'Imported Tokens',
						href: '/app/tools/your-contracts/imported-tokens',
						icon: 'bi-coin',
					},
					{
						label: 'Imported Contracts',
						href: '/app/tools/your-contracts/imported-contracts',
						icon: 'bi-code-square',
					},
				],
			},
			{
				icon: 'rocket',
				href: '/app/boosters/kol-marketplace',
				label: 'KOL Marketplace',
				isNew: true,
				// options: [
				// 	{
				// 		icon: 'rocket',
				// 		href: '/app/boosters/kol-marketplace',
				// 		label: 'KOL Marketplace',
				// 	},
				// 	{
				// 		icon: 'rocket',
				// 		href: '/app/boosters/partnerships',
				// 		label: 'Partnerships',
				// 	},
				// ],
			},
		],
	},
];
