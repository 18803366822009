import { links } from '@/config/links';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';
import {
	CampaignRewardCategory,
	ICampaignAddReward,
} from '@/features/campaigns/types';
import CustomReward from './components/reward-methods/AddCustomReward';
import DiscordRoleReward from './components/reward-methods/AddDiscordRole';
import AddOfficialPoints from './components/reward-methods/AddOfficialPointsReward';
import AddRewardMethods from './components/reward-methods/AddRewardMethods';
import NFTReward from './components/reward-methods/reward/AddNFTReward';
import ERC20Reward from './components/reward-methods/token/AddERC20TokenReward';

const CampaignRewards = () => {
	const { mode, rewards, setRewards, errors, setIsDirty, details, setDetails } =
		useCreateCampaign();

	const updateReward = (
		category: CampaignRewardCategory,
		reward: ICampaignAddReward,
	) => {
		setIsDirty(true);
		setRewards((prev) =>
			prev.map((r) => (r.category === category ? reward : r)),
		);
	};

	return (
		<div>
			<div className="flex justify-center relative items-center pt-10">
				<div className="absolute top-0 start-0 mt-10 ms-10"></div>
				<div className="mb-5 w-[680px] relative">
					<h4 className="text-xl font-medium">Add Campaign Rewards</h4>
					<p className="text-muted-foreground text-xs mt-1 mb-4">
						Configure the rewards for your campaign.
						<a
							href={links.docs.rewards}
							target="_blank"
							rel="noopener noreferrer"
							className="ms-1 font-medium text-blue-800 dark:text-blue-800 hover:underline"
						>
							Learn more about rewards
						</a>
					</p>
					<AddRewardMethods rewards={rewards} mode={mode} />
					<div className="mt-4 w-full">
						{rewards
							?.filter((reward) => reward.isActive)
							.map((reward) => {
								switch (reward.category) {
									case CampaignRewardCategory.DiscordRole:
										return (
											<DiscordRoleReward
												key="discord"
												mode={mode}
												discordRoleReward={reward}
												setDiscordRoleReward={(discord) =>
													updateReward(
														CampaignRewardCategory.DiscordRole,
														discord,
													)
												}
												errors={errors?.rewards?.discord}
												noEndTime={details?.noEndTime}
											/>
										);
									case CampaignRewardCategory.Token:
										return (
											<ERC20Reward
												key="erc20"
												tokenReward={reward}
												setTokenReward={(token) =>
													updateReward(
														CampaignRewardCategory.Token,
														token,
													)
												}
												errors={errors?.rewards?.token}
												noEndTime={details?.noEndTime}
											/>
										);
									case CampaignRewardCategory.Nft:
										return (
											<NFTReward
												key="nft"
												nftReward={reward}
												setNftReward={(nft) =>
													updateReward(
														CampaignRewardCategory.Nft,
														nft,
													)
												}
												errors={errors?.rewards?.nft}
												noEndTime={details?.noEndTime}
											/>
										);
									case CampaignRewardCategory.Whitelist:
										return (
											<CustomReward
												key="custom"
												whitelistReward={reward}
												setWhitelistReward={(whitelist) =>
													updateReward(
														CampaignRewardCategory.Whitelist,
														whitelist,
													)
												}
												errors={errors?.rewards?.whitelist}
												noEndTime={details?.noEndTime}
											/>
										);
									case CampaignRewardCategory.OfficialPoints:
										return (
											<AddOfficialPoints
												key="officialPoints"
												officialPointsReward={reward}
												setOfficialPointsReward={(
													officialPoints,
												) =>
													updateReward(
														CampaignRewardCategory.OfficialPoints,
														officialPoints,
													)
												}
												errors={errors?.rewards?.whitelist}
												noEndTime={details?.noEndTime}
												enableCustomReferralPoints={
													details?.referral
														?.enableCustomReferralPoints
												}
												customPoints={
													details?.referral?.customPoints
												}
												details={details}
												setDetails={setDetails}
												referralTaskEnabled={
													details?.referral
														?.referralTaskEnabled
												}
											/>
										);
									default:
										return null;
								}
							})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CampaignRewards;
